import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';
import { configuration } from '../environments/env';
import { useLayoutContex } from '@providers/LayoutProvider';
import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { MainHeader } from '@components/Layout/HeaderLayout/MainHeader';
import { usePageContentSize } from '@HOOKs/UsePageContentSize';

export default function ProjectPage() {

  const PageContent = () => {

    const { styleContent } = useLayoutContex();
    const [layoutRef]= usePageContentSize()

    return (
      <Layout >
          <MainHeader />
          <Content ref={layoutRef} style={styleContent}>
            <Outlet />
          </Content>
      </Layout>
    )
  }

  const Page = () => (
    <>
      <Helmet>
        <title>Projects | { configuration.APP_NAME }</title>
      </Helmet>
      <PageContent />
    </>
  )
  return (<Page />)
}
