import { createContext, useContext, useEffect, useState } from "react";
import { IUserPreferences, IUserSettings } from '@models/user.model';

import elegant from "./../assets/sounds-notifications/elegant-notification-sound.mp3";
import goodNews from "./../assets/sounds-notifications/good-news-notification-sound.mp3";
import intuition from "./../assets/sounds-notifications/intuition-notification-sound.mp3";
import juntos from "./../assets/sounds-notifications/juntos-notification-sound.mp3";
import pristine from "./../assets/sounds-notifications/pristine-notification-sound.mp3";

import { configuration } from '../environments/env';
import { defaultLangKey } from "@constants/languages";
import i18n from 'i18next';

export const PreferencesContext = createContext({} as any);

export const APPEARANCES = {
    LIGHT: "LIGHT",
    DARK: "DARK"
}

export const DATE_FORMATS = {
    US: "US",
    EU: "EU"
}

export const MEAUREMRENT_UNITS = {
    ft: "ft",
    m: "m"
}

export const NOTIFICATION_SOUNDS: INotificationSoundItem[] = [
  { id: 1, name: "None", src: '', hasSound: false },
  { id: 2, name: "Elegant", src: elegant, hasSound: true },
  { id: 3, name: "Good News", src: goodNews, hasSound: true },
  { id: 4, name: "Intuition", src: intuition, hasSound: true },
  { id: 5, name: "Juntos", src: juntos, hasSound: true },
  { id: 6, name: "Pristine", src: pristine, hasSound: true },
]

export const NOTIFICATION_SOUNDS_MAP: Record<number, INotificationSoundItem> = NOTIFICATION_SOUNDS.reduce((acc, item ) => ({ ...acc, [item.id]: item }), {});

export const NOTIFICATION_PLACEMENT = {
    TOP_LEFT: "topLeft",
    TOP_RIGHT: "topRight" 
}
  
export interface INotificationSoundItem {
    id: number,
    name: string,
    src: string,
    hasSound: boolean
}

export const isAppeareanceLightMode = (appearance: keyof typeof APPEARANCES) => appearance === APPEARANCES.LIGHT
export const isAppeareanceDarkMode = (appearance: keyof typeof APPEARANCES) => appearance === APPEARANCES.DARK

export const PreferencesProvider = ({ children }: any) => {

    const [showModalPreferences, setVisibleModalPreferences] = useState(false);
    const [userSettings, setUserSettings] = useState<IUserSettings | null>(null)
    const [appearance, setAppearance] = useState(APPEARANCES.LIGHT);
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [dateFormat, setDateFormat] = useState(DATE_FORMATS.US);
    const [measurementUnit, setMeasurementUnit] = useState(MEAUREMRENT_UNITS.ft);
    const [soundNotification, setSoundNotification] = useState("");
    const [placementNotification, setPlacementNotification] = useState(NOTIFICATION_PLACEMENT.TOP_LEFT);

    useEffect(() => {
        const clientDefaultTranslationKey = configuration.APP_CLIENT_DEFAULT_TRANSLATION_KEY;
        if (clientDefaultTranslationKey) {
            i18n.changeLanguage(`${clientDefaultTranslationKey}`).catch(() => { i18n.changeLanguage(`${defaultLangKey}`); });
        }
    }, [])

    const setPreferences = (userSettings: IUserSettings | null | undefined) => {
        if (!userSettings) {
            setUserSettings(null);
            return;
        }

        userSettings.settings = JSON.parse(userSettings.settings as string);
        setUserSettings(userSettings);
        const { settings } = userSettings;

        if (settings) {
            const settingsPreferences = settings as IUserPreferences
            const keyAppearance = settingsPreferences?.appearance as keyof typeof APPEARANCES
            const keyDateFormat = settingsPreferences?.dateFormat as keyof typeof DATE_FORMATS
            const keyMeasurementUnit = settingsPreferences?.measurementUnit as keyof typeof DATE_FORMATS
            
            const dateFormat = DATE_FORMATS[keyDateFormat];
            if (dateFormat) {
                setDateFormat(dateFormat);
            }

            const measurementUnit = MEAUREMRENT_UNITS[keyMeasurementUnit];
            if (measurementUnit) {
                setMeasurementUnit(measurementUnit);
            }

            const appearance = APPEARANCES[keyAppearance];
            if (appearance) {
                setAppearance(appearance);
            }

            const keyNotificationSound = settingsPreferences.notification_soundId as number;
            if (Number.isInteger(keyNotificationSound)) {
                const soundSelected = NOTIFICATION_SOUNDS_MAP[keyNotificationSound as number];
                setSoundNotification(soundSelected.src);
            }

            const keyNotificationPlacement = settingsPreferences.notification_placement as keyof typeof NOTIFICATION_PLACEMENT;
            if (keyNotificationPlacement) {
                setPlacementNotification(keyNotificationPlacement);
            }
        }
    }

    const setAppearanceDefault = () => {
        if (appearance === APPEARANCES.LIGHT) return;
        setAppearance(APPEARANCES.LIGHT);
    }

    const setDarkMode = () => {
        if (appearance === APPEARANCES.DARK) return;
        setAppearance(APPEARANCES.DARK);
    }

    const setLightMode = () => {
        if (appearance === APPEARANCES.LIGHT) return;
        setAppearance(APPEARANCES.LIGHT);
    }

    const value = {
        setPreferences,
        userSettings,
        showModalPreferences,
        appearance,
        dateFormat,
        measurementUnit,
        soundNotification,
        placementNotification,
        setAppearanceDefault,
        setLightMode,
        setDarkMode,
        setVisibleModalPreferences: (visible: boolean) => setVisibleModalPreferences(visible),
        setSoundNotification: (sound: string) => setSoundNotification(sound),
        setPlacementNotification: (placement: string) => setPlacementNotification(placement)
    }

    return (
        <PreferencesContext.Provider value={value}>{children}</PreferencesContext.Provider>
    )
};

export const usePreferencesProvider = () => {
    return useContext(PreferencesContext);
};