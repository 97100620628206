import { http } from '../http/axios.instance';
import { IResourcePermission } from '@models/resource-permission.model';

const path = 'resource-permissions';

export const resourcePermissionRepository = {
  getResourcePermissions: async () => {
    const requestUrl = `${path}?cacheBuster=${new Date().getTime()}`;
    const promiseGetAll = await http.get<IResourcePermission[]>(requestUrl);
    return promiseGetAll;
  },
  createResourcePermission: async (entity: IResourcePermission): Promise<IResourcePermission> => {
    const requestUrl = `${path}`;
    const promiseCreate = await http.post<IResourcePermission>(requestUrl, entity);
    return promiseCreate.data;
  },
  deleteResourcePermission: async (id: string | number): Promise<void> => {
    const requestUrl = `${path}/${id}`;
    await http.delete(requestUrl);
  }
};