import { useEffect, useState, useRef } from 'react';
import { Badge, Button, Divider, theme, Select, Tooltip } from 'antd';
import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@store/store';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { getEntitiesEx } from '@store/slices/projects';
import { PAGINATION_SIZE_PROJECT_TABLE } from '@constants/core';
import { useLayoutContex } from '@providers/LayoutProvider';
import { IHandleOnSearchProps, SearchTable } from '@components/Utils/SearchTable';
import { useProjectsContainerContext } from './Projects';
import { AppstoreOutlined, DownloadOutlined, PlusOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { OverviewTable } from './OverviewTable';
import { MilestoneTable } from './MilestoneTable';

const { Text } = Typography;

// Define ViewMode enum
enum ViewMode {
  OVERVIEW = 'overview',
  MILESTONE = 'milestone',
}

// Simplified helper function that only handles the download part
const downloadCSV = (csvData: string[][], fileName: string) => {
  // Add headers to the beginning of the data

  // Convert to CSV format - handle commas within data fields with quotes
  const csvContent = csvData
    .map(row =>
      row
        .map(cell => {
          const cellStr = String(cell);
          return cellStr.includes(',') ? `"${cellStr}"` : cellStr;
        })
        .join(',')
    )
    .join('\n');

  // Create a blob and download
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const ProjectsTable = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { setHeaderLabelEntityState } = useLayoutContex();
  const { heightContainer } = useProjectsContainerContext();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState(PAGINATION_SIZE_PROJECT_TABLE);
  const [sortingKey, setSortingKey] = useState<string>('id');
  const [sortingOrder, setSortingOrder] = useState<string>('asc');
  const [nameFilter, setNameFilter] = useState('');
  const [statusFilters, setStatusFilters] = useState<{ text: string; value: string }[]>([]);
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.OVERVIEW);
  const [projectTemplateList, setProjectTemplateList] = useState<string[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);

  const getTableData = async () => {
    dispatch(
      getEntitiesEx({
        page: pageNumber - 1,
        size: itemsPerPage,
        sort: `${sortingKey},${sortingOrder}`,
        nameFilter: nameFilter,
      })
    );
  };

  useEffect(() => {
    getTableData();
    setHeaderLabelEntityState(t('generic.projects'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, itemsPerPage, sortingKey, nameFilter, sortingOrder]);

  const navigate = useNavigate();

  const { entities: projectList, totalItems, loading } = useAppSelector(state => state.Project);

  // Generate status filters dynamically from project data
  useEffect(() => {
    if (!projectList || projectList.length === 0) return;

    const templates = projectList.map(project => project.template?.name).filter((templateName): templateName is string => !!templateName);

    const uniqueTemplates = Array.from(new Set(templates));

    setProjectTemplateList(uniqueTemplates);
  }, [projectList]);

  useEffect(() => {
    if (projectList && projectList.length > 0) {
      const uniqueStatuses = Array.from(
        new Set(
          projectList.filter(project => project.projectStatus && project.projectStatus.name).map(project => project.projectStatus!.name)
        )
      );

      const filters = uniqueStatuses.map(status => ({
        text: status!,
        value: status!,
      }));

      setStatusFilters(filters);
    }
  }, [projectList]);

  const {
    token: { colorPrimary, colorBorderSecondary, colorBgElevated },
  } = theme.useToken();

  const navigateToProjectDetails = record => {
    const urlToNavigate = `/location/${record.site.location.id}/sites/${record.site.id}/project/${record.id}`;
    navigate(`${urlToNavigate}`);
  };

  const handleOnSearch = (props?: IHandleOnSearchProps) => {
    const { valueToSearch = ' ' } = props as IHandleOnSearchProps;
    setNameFilter(valueToSearch);
  };

  // Reference to the table components for accessing their methods
  const overviewTableRef = useRef<{ getCSVData: () => string[][]; showDisplayOptions: () => void } | null>(null);
  const milestoneTableRef = useRef<{ getCSVData: () => string[][]; showDisplayOptions: () => void } | null>(null);

  const showDrawer = () => {
    if (viewMode === ViewMode.OVERVIEW) {
      if (overviewTableRef.current) {
        overviewTableRef.current.showDisplayOptions();
      }
    } else {
      if (milestoneTableRef.current && selectedTemplate) {
        milestoneTableRef.current.showDisplayOptions();
      }
    }
  };

  const handleExportCSV = () => {
    const date = new Date().toISOString().split('T')[0];
    const fileName = `projects-export-${viewMode}-${date}.csv`;

    if (viewMode === ViewMode.OVERVIEW) {
      if (overviewTableRef.current) {
        const csvData = overviewTableRef.current.getCSVData();
        downloadCSV(csvData, fileName);
      }
    } else {
      if (milestoneTableRef.current && selectedTemplate) {
        const csvData = milestoneTableRef.current.getCSVData();
        downloadCSV(csvData, fileName);
      }
    }
  };

  return (
    <div>
      <div
        style={{
          border: `1px solid ${colorBorderSecondary}`,
          margin: '10px 0',
          borderRadius: '5px',
          padding: '0.5rem 0.5rem 0.5rem 1rem',
          backgroundColor: colorBgElevated,
        }}
        className="flex justify-between"
      >
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <Text>{t('generic.project')}</Text>
          <Badge
            count={totalItems}
            size="small"
            className="ml-30 mr-30"
            style={{ backgroundColor: colorPrimary, outline: 'none', color: 'primary' }}
          />
          <Divider type="vertical" className="ml-20" style={{ height: '24px' }} plain />
          <Text>{t('generic.viewBy')}</Text>
          <Button.Group style={{ marginRight: '16px', marginLeft: '16px' }}>
            <Tooltip title="Overview View">
              <Button
                type={viewMode === ViewMode.OVERVIEW ? 'primary' : 'default'}
                icon={<UnorderedListOutlined />}
                onClick={() => setViewMode(ViewMode.OVERVIEW)}
              />
            </Tooltip>
            <Tooltip title="Milestone View">
              <Button
                type={viewMode === ViewMode.MILESTONE ? 'primary' : 'default'}
                icon={<AppstoreOutlined />}
                onClick={() => setViewMode(ViewMode.MILESTONE)}
              />
            </Tooltip>
          </Button.Group>

          {viewMode === ViewMode.MILESTONE && (
            <Select
              style={{ width: 200 }}
              placeholder="Select a project template"
              allowClear
              onChange={value => setSelectedTemplate(value)}
              options={projectTemplateList.map(template => ({
                value: template,
                label: template,
              }))}
            />
          )}
        </div>

        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <SearchTable
            className="ml-20"
            handleOnSearch={handleOnSearch}
            placeholder="Quick Filter by Project ID"
            onClear={() => setNameFilter('')}
          />
          <Tooltip title="Configure display options">
            <Button onClick={showDrawer} className="ml-30" type="primary" icon={<PlusOutlined />} color={colorPrimary}>
              Display Options
            </Button>
          </Tooltip>
          <Tooltip title="Export Data as CSV">
            <Button onClick={handleExportCSV} className="ml-30" type="primary" icon={<DownloadOutlined />} color={colorPrimary}>
              Export
            </Button>
          </Tooltip>
        </div>
      </div>

      {viewMode === ViewMode.OVERVIEW ? (
        <>
          <OverviewTable
            ref={overviewTableRef}
            projectList={[...projectList]}
            loading={loading}
            statusFilters={statusFilters}
            heightContainer={heightContainer}
            setSortingKey={setSortingKey}
            setItemsPerPage={setItemsPerPage}
            setSortingOrder={setSortingOrder}
            setPageNumber={setPageNumber}
            sortingOrder={sortingOrder}
            navigateToProjectDetails={navigateToProjectDetails}
          />
        </>
      ) : (
        <>
          {selectedTemplate && (
            <MilestoneTable
              ref={milestoneTableRef}
              selectedTemplate={selectedTemplate}
              projectList={[...projectList]}
              loading={loading}
              statusFilters={statusFilters}
              heightContainer={heightContainer}
              setSortingKey={setSortingKey}
              setSortingOrder={setSortingOrder}
              setItemsPerPage={setItemsPerPage}
              setPageNumber={setPageNumber}
              sortingOrder={sortingOrder}
              navigateToProjectDetails={navigateToProjectDetails}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ProjectsTable;
