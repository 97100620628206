export interface IBulkData {
  dataType: string;
  csvData: string;
}

export interface IUploadDataResponse {
  batchId: string;
  totalRecords: number;
  totalValidRecords: number;
  totalInvalidRecords: number;
}


export interface ICommitBatchResponse {
  batchId: string;
  totalSuccess: number;
  totalError: number;
}

export interface IBulkUploadRecord {
  batchId: string;
  batchRecordId: string;
  index: number;
  rawData: string;
  data: string;
  status: string;
  warningMessage?: string;
  errorMessage?: string;
  referenceId?: string;
  createdAt?: string;
}

export interface IBulkUploadBatch {
  batchId?: string;
  dataType?: string;
  totalRecords? : number;
  status?: string;  
  createdAt?: string;
}

  export const defaultValue: Readonly<IBulkUploadBatch> = {};