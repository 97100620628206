import { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Col, Empty, Row, Skeleton, theme, Typography } from 'antd';
import { AppDispatch, useAppSelector } from '@store/store';
import { getProjectOverviewData } from '@store/slices/project-overview';
import { useDateFormatter } from '@shared/util/date-utils';
import { useLayoutContex } from '@providers/LayoutProvider';
import { EmptyLabel } from '../../../Utils/EmptyLabel';
import { useProviderColor } from '@HOOKs/UseProviderColor';
import { ListTeam } from './OverviewTab/ListTeam';
import { useBaseProjectTabUrl } from './RouteBaseProjectTabs';
import { ListCompanies } from './OverviewTab/ListCompanies';
import { DEFAULT_COLOR } from '@providers/ThemeProvider';
import { OverviewHeader } from './OverviewTab/OverviewHeader';
import { CustomFieldsGenericList } from '@components/CustomFieldsGeneric/CustomFieldsGenericList';
import { CompanyReferenceEnum } from '@models/enumerations/company-reference-enum.model';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

export const processStatusPipe = (status: string) => {
  const statusMap = {
    NOT_STARTED: 'Not Started',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
  };
  return statusMap.hasOwnProperty(status) && statusMap[status as keyof typeof statusMap];
};

export const ProjectOverviewTab = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { t } = useTranslation();

  const {
    token: { padding, marginLG, colorBorder, fontSize, colorBgContainer, colorBgElevated, fontSizeHeading5 },
  } = theme.useToken();

  const { projectId } = useParams<'projectId'>();

  const { data: ProjectOverview, loading } = useAppSelector(state => state.ProjectOverview);

  const { layoutSize, headerSize } = useLayoutContex();

  const reference = useRef<HTMLDivElement>(null);

  const [heghtTab, setheghtTab] = useState(0);

  const [blackColor] = useProviderColor(DEFAULT_COLOR);

  const processStatusColorPipe = (status: string) => {
    const statusMap = {
      NOT_STARTED: blackColor,
      IN_PROGRESS: '#b1b1b1',
      COMPLETED: '#4FC476',
    };
    return statusMap.hasOwnProperty(status) ? statusMap[status as keyof typeof statusMap] : undefined;
  };

  useEffect(() => {
    projectId && dispatch(getProjectOverviewData(projectId));
  }, [dispatch, projectId]);

  useLayoutEffect(() => {
    const topPosition = (reference?.current && reference?.current?.getBoundingClientRect().top) || 0;
    const tabBarHeightRelative = topPosition - headerSize;
    setheghtTab(layoutSize?.layoutHeight - tabBarHeightRelative);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutSize]);

  const navigate = useNavigate();
  const { validBaseUrl } = useBaseProjectTabUrl();
  const { dfFormatDatePipe } = useDateFormatter();

  const onSelectKeyMilestone = (deliverableId: number) => {
    Number.isInteger(deliverableId) && navigate(`${validBaseUrl}/deliverables/${deliverableId}`);
  };

  return (
    <div ref={reference}>
      <Row className="flex flex-row overflow-auto" style={{ height: `${heghtTab}px`, backgroundColor: colorBgContainer }}>
        <Col span={18} style={{ borderRight: `1.2px solid ${colorBorder}` }}>
          <div className="flex flex-col mt-10">
            <OverviewHeader />

            <ListTeam referenceId={projectId} referenceType={CompanyReferenceEnum.PROJECT} />

            <ListCompanies />

            <CustomFieldsGenericList referenceId={projectId} referenceType={CompanyReferenceEnum.PROJECT} />
          </div>
        </Col>
        <Col span={6}>
          <div className="flex flex-col overflow-auto relative" style={{ height: `${heghtTab}px` }}>
            <span
              className="font-bold pt-20 pb-20 w-full"
              style={{
                paddingLeft: `${marginLG}px`,
                marginRight: `${marginLG}px`,
                fontSize: fontSizeHeading5,
                position: 'sticky',
                top: 0,
                left: 0,
                backgroundColor: colorBgContainer,
              }}
            >
              {t('project.overview.keyMilestones')}:
            </span>
            <Skeleton
              loading={loading}
              paragraph={{ rows: 24 }}
              active={true}
              className="pt-10 mt-30"
              style={{ paddingLeft: `${marginLG}px`, paddingRight: `${marginLG}px` }}
            >
              {ProjectOverview && ProjectOverview?.keyMilestoneList?.length < 1 ? (
                <Row align={'middle'} className="pt-40 mt-40 w-full items-center justify-center">
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </Row>
              ) : (
                <div className="w-full" style={{ paddingLeft: `${marginLG}px`, paddingRight: `${marginLG}px` }}>
                  {ProjectOverview &&
                    ProjectOverview?.keyMilestoneList?.map((milestone: any, index: number) => (
                      <div
                        key={index}
                        onClick={() => onSelectKeyMilestone(milestone?.projectDeliverableId)}
                        className="mb-10 cursor-pointer"
                        style={{ border: `1.2px solid ${colorBorder}`, borderRadius: '5px' }}
                      >
                        <div
                          style={{
                            backgroundColor: processStatusColorPipe(milestone?.status),
                            height: '4px',
                            borderStartStartRadius: '5px',
                            borderStartEndRadius: '5px',
                          }}
                        ></div>
                        <div
                          className="flex flex-col"
                          style={{ padding, backgroundColor: colorBgElevated, borderEndStartRadius: '5px', borderEndEndRadius: '5px' }}
                        >
                          <div className="mb-10">
                            <Text
                              className="font-bold"
                              style={{ fontSize: fontSize + 1 }}
                              ellipsis={true ? { tooltip: milestone?.title || <EmptyLabel /> } : false}
                            >
                              {milestone?.title || <EmptyLabel />}
                            </Text>
                          </div>
                          <div className="flex flex-row justify-between">
                            <span style={{ fontWeight: 500, color: processStatusColorPipe(milestone.status) }}>
                              {processStatusPipe(milestone?.status) || <EmptyLabel />}
                            </span>
                            <span style={{ fontWeight: 300, fontSize: fontSize - 1 }}>
                              {(milestone?.date && milestone?.date !== null && dfFormatDatePipe(milestone?.date)) || <EmptyLabel />}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </Skeleton>
          </div>
        </Col>
      </Row>
    </div>
  );
};