import useColorStatus from '@HOOKs/UseColorStatus';
import { IProjectDeliverableUI } from '@models/project-deliverable';
import { ReactElement, useLayoutEffect } from 'react';
import { theme, Tooltip } from 'antd';
import { WarningSvgIcon } from '@components/Icons/WarningIcon';

export interface StatusLabelProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
    deliverable: IProjectDeliverableUI,
    renderOnEmpty?: ReactElement<unknown>
}

export const StatusProjectDeliverableLabel = ({ deliverable, style, renderOnEmpty, ...restProps }: StatusLabelProps) => {
    const { token: { fontSizeSM } } = theme.useToken();
    const [colorStatus, setStatus] = useColorStatus();

    useLayoutEffect(() => {
        deliverable?.status && setStatus(deliverable?.status)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliverable])

    return (
      <>
          <span style={ colorStatus ? { ...style, color: colorStatus } : style }>{ deliverable.statusLabelUI || renderOnEmpty }{' '}{ deliverable.atRiskFlag && <Tooltip title={"At Risk"}><WarningSvgIcon style={{ color: "#ff0000", fontSize: fontSizeSM }} /></Tooltip>}</span>
      </>
    )
}
