/* eslint-disable react-hooks/exhaustive-deps */
import { IMarkerFullData, MarkerOptions, WrapperGoogleMap } from "@components/Google/Map/WrapperGoogleMap";
import { ILocation, ILocationUI } from "@models/location.model";
import { isArrayWithValues } from "@shared/util/array-util";
import { Card, Col, Divider, Row, Spin, Tooltip, theme } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { LocationKeyPhotoThumbail } from './LocationKeyPhotoThumbail';
import { formatFullDatetimeWhitoutDayName, useDateFormatter } from "@shared/util/date-utils";
import { EmptyLabel } from "@components/Utils/EmptyLabel";
import { ILOCATION_STATUS_MAP_KEYS, StatusCell } from '../LocationStatusTag';
import { CustomButtonText } from "@components/Utils/CustomButtonText";
import { ChevronRightSvgIcon } from "@components/Icons/ChevronRightSvgIcon";
import { LoadingOutlined } from '@ant-design/icons';
import { If } from '@components/Utils/Structural';
import { useNavigate } from 'react-router-dom';
import "./PopupMap.less";
import { GoogleAdvancedMarker, GoogleAdvancedMarkerActive } from "@components/Icons/GoogleAdvancedMarker";
import ReactDOMServer from 'react-dom/server';

const parser = new DOMParser();

interface ILocationsMapViewProps {
    loading?: boolean;
    locationsList: ILocationUI[];
}

export interface ICustomMarkerFullData { 
    marker: google.maps.marker.AdvancedMarkerElement;
    extraData: ILocation;
}

export const LocationsMapView = (props: ILocationsMapViewProps) => {

    const { locationsList, loading } =  props; // useAppSelector((state) => state.LocationExtended);

    const [markersOptions, setMarkersOptions] = useState<MarkerOptions[]>([]);
    const [locationsFiltered, setLocationsFiltered] = useState<ICustomMarkerFullData[]>([]);
    const [locationSelected, setLocationSelected] = useState<ILocation>();

    const { token: { colorPrimary, colorBgBase, colorBorder, colorBorderSecondary, colorText }} = theme.useToken();

    useEffect(() => {
        processMarkerOptions(locationsList)
    }, [locationsList])

    const processMarkerOptions = (list: ILocation[]) => {
        if (list && isArrayWithValues(list)) {
            const locationsWithCoordinates = list.filter((location) => (location?.address?.longitude && location?.address?.latitude))
            const markersPropsOptions = locationsWithCoordinates.map((location) => {
                return {
                    position: { lat: +location?.address?.latitude!, lng: +location.address?.longitude! },
                    gmpClickable: true,
                    extraData: location
                }
            })
            markersPropsOptions && setMarkersOptions(markersPropsOptions);
        }
    }

    const navigate = useNavigate();
    const { dfFormatDatePipe } = useDateFormatter();

    const viewLocation = (location: ILocation) => {
        const urlToNavigate = `/location/${location.id}`;
        navigate(`${urlToNavigate}`);
    }

    const reference = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(700);

    useEffect(() => {
        const parent = reference?.current?.offsetParent?.scrollHeight || 0;
        const topPosition = (reference?.current && reference?.current.offsetTop) || 0;
        if (parent !== 0) {
            setHeight(parent - topPosition - 16)
        }
    }, []);

    const onMarkersGenerated = (markers: IMarkerFullData[]) => {
        setLocationsFiltered(markers as ICustomMarkerFullData[])
    }

    const latestMarkerSelected = useRef<google.maps.marker.AdvancedMarkerElement | null>(null);

    const onClickCard = (marker: google.maps.marker.AdvancedMarkerElement, location: ILocation) => {
        // Pan to Marker with Smooth Animation
        marker.map?.panTo?.(marker.position as google.maps.LatLng);
        //Process Click on Card
        onProcessMarkerSelected(marker, location);
    }

    const onProcessMarkerSelected = (marker: google.maps.marker.AdvancedMarkerElement, location: ILocation) => {

        if (latestMarkerSelected.current) {
            const pinSvg = parser.parseFromString(ReactDOMServer.renderToString(<GoogleAdvancedMarker />), 'image/svg+xml').documentElement;
            latestMarkerSelected.current.content = pinSvg
        }
        
        const pinSvgActive = parser.parseFromString(ReactDOMServer.renderToString(<GoogleAdvancedMarkerActive />), 'image/svg+xml').documentElement;
        marker.content = pinSvgActive;
        marker.zIndex = 1000000;
        latestMarkerSelected.current = marker;

        // Add Tooltip to Marker
        const map = marker.map as google.maps.Map;

        /**
         * A customized popup on the map.
        */
        class Popup extends google.maps.OverlayView {
            position: google.maps.LatLng;
            containerDiv: HTMLDivElement;

            constructor(position: google.maps.LatLng, content: HTMLElement) {
                super();
                this.position = position;


                if(document.getElementById("custom-popup-container-id")) {
                    document.getElementById("custom-popup-container-id")?.remove?.();
                }

                if (content) { 
                    content?.classList?.add("custom-popup-bubble");
                }
                // This zero-height div is positioned at the bottom of the bubble.
                const bubbleAnchor = document.createElement("div");

                bubbleAnchor?.classList?.add?.("custom-popup-bubble-anchor");
                
                if (content) {
                    bubbleAnchor?.appendChild?.(content);
                }

                // This zero-height div is positioned at the bottom of the tip.
                this.containerDiv = document?.createElement?.("div");
                this.containerDiv.id = "custom-popup-container-id";
                this.containerDiv?.classList?.add?.("custom-popup-container");
                this.containerDiv?.appendChild?.(bubbleAnchor);

                // Optionally stop clicks, etc., from bubbling up to the map.
                Popup?.preventMapHitsAndGesturesFrom?.(this.containerDiv);
            }

            /** Called when the popup is added to the map. */
            onAdd() {
                this.getPanes()!.floatPane?.appendChild?.(this.containerDiv);
            }

            /** Called when the popup is removed from the map. */
            onRemove() {
                if (this.containerDiv?.parentElement) {
                    this.containerDiv?.parentElement?.removeChild?.(this.containerDiv);
                    this.containerDiv?.removeChild(this.containerDiv?.firstChild!)
                }
            }

            /** Called each frame when the popup needs to draw itself. */
            draw() {
                const divPosition = this.getProjection().fromLatLngToDivPixel(
                    this.position
                )!;

                // Hide the popup when it is far out of view.
                const display =
                    Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
                    ? "block"
                    : "none";

                if (display === "block") {
                    this.containerDiv.style.left = divPosition.x + "px";
                    this.containerDiv.style.top = divPosition.y + "px";
                }

                if (this.containerDiv.style.display !== display) {
                    this.containerDiv.style.display = display;
                }
            }
        }
        
        setTimeout(() => {
            const popup = new Popup(
                marker.position as google.maps.LatLng,
                document.getElementById("infowindow-content-layer") as HTMLElement
            );
            popup.setMap(null);
            popup.setMap(map);

            // Highlight Card
            highLightCard(location);
        }, 150);
    }

    const handleClickMarker = (map: google.maps.Map, marker: google.maps.marker.AdvancedMarkerElement, extraData: ILocation) => {
        onProcessMarkerSelected(marker, extraData);
    }

    const highLightCard = (location) => {
        setLocationSelected(location);
    }

    const containerStyle = {
        width: '100%',
        height: `${height || 500}px`,
        border: `2px solid ${colorBorder}`,
        borderRadius: 4
    };

    const map = useMemo(() => { return (
        <WrapperGoogleMap 
            markers={markersOptions}
            customMapOptions={{ fitToBounds: { top: 16, right: -100, bottom: 16, left: 336 }, center: false }}
            handleClickMarker={handleClickMarker}
            onMarkersGenerated={onMarkersGenerated}
        />
    ) }, [markersOptions]);

    const popupActive = useMemo(() => {
        return (
            <>
                <If condition={Boolean(locationSelected)}>      
                    <Row className='flex flex-row pr-16 pt-16 pl-16 pb-16' style={{
                    }}>
                        <div  className='flex flex-col' style={{ maxWidth: 80, width: 80 }}>
                            <div>
                                <LocationKeyPhotoThumbail location={locationSelected!} />
                            </div>
                        </div>
                        <div className='flex flex-col justify-between pl-10' style={{ maxWidth: '11rem' }}>
                            <div style={{ color: colorText }}>{ locationSelected?.name || <EmptyLabel />}</div>
                            <CustomButtonText onClick={() => viewLocation(locationSelected!)} className='self-end'> 
                                View
                                <ChevronRightSvgIcon className="ml-8" />
                            </CustomButtonText>
                        </div>
                    </Row>
                </If>
            </>
        )
    }, [locationSelected, colorPrimary, colorText]);
    
    return (
        <div ref={reference} >
            <Row className="w-full mt-20"  >
                <Col className="w-full">
                    <div 
                        className="relative w-full"
                        style={containerStyle}
                    >
                        {map}
                        
                        <div 
                            className="absolute z10 overflow-scroll pr-16 pt-16 pl-16 top-0 left-0" 
                            style={{
                                height: `calc(${height}px - 4px)`, 
                                background: colorBgBase,
                                borderRight: `1px solid ${colorBorderSecondary}` 
                            }}
                        >   
                            <If condition={Boolean(loading)}>
                                <Card
                                     className="mb-8"
                                     styles={{ body: { padding: 16 } }}
                                     style={{ borderRadius: 4, border: `1px solid transparent` }}
                                >
                                     <Row style={{ width: 336, maxWidth: 336 }}>
                                        <div className="flex flex-row items-center justify-center pt-30 w-full">
                                            <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} spin={true} />} />
                                        </div>
                                     </Row>
                                </Card>
                            </If>
                            <If condition={Boolean(!loading)}>
                                <>
                                    {
                                        locationsFiltered.map(({ marker, extraData: location} : ICustomMarkerFullData ) => (
                                            <Card 
                                                onClick={() => onClickCard(marker, location)}
                                                className="mb-8"
                                                style={{ 
                                                    borderRadius: 4,
                                                    border: `1px solid ${locationSelected?.id === location?.id ? colorPrimary : colorBorderSecondary }`,
                                                    outline: `1px solid ${locationSelected?.id === location?.id ? colorPrimary : 'transparent' }`
                                                }}
                                                styles={{ body: { padding: 16 }}} 
                                            >
                                                <Row style={{ width: 336, maxWidth: 336 }}>
                                                    <Col flex={"none"} style={{ maxWidth: 90, width: 90 }}>
                                                        <div>
                                                            <LocationKeyPhotoThumbail location={location} />
                                                        </div>
                                                    </Col>
                                                    <Col flex={"auto"} className="pl-10 w-min">
                                                        <Row justify={"space-between"}>
                                                            <Col>
                                                                <div>{ location?.name || <EmptyLabel />}</div>
                                                            </Col>
                                                            <Col> 
                                                                <Tooltip title={formatFullDatetimeWhitoutDayName(location?.openDate)}>
                                                                    <span className="text-color-neutral-6">
                                                                    { dfFormatDatePipe(location?.openDate) }
                                                                    </span>
                                                                </Tooltip>
                                                            </Col> 
                                                        </Row>
                                                        <Row>
                                                            <Col className="text-color-neutral-6 mt-6">
                                                                <span className="font-bold">{ location?.brand?.description || <EmptyLabel /> }</span>
                                                                <Divider type="vertical"  />
                                                                <span>{ location?.market?.description || <EmptyLabel /> }</span>
                                                            </Col> 
                                                        </Row>
                                                        <Row className="pt-8" justify={"space-between"}>
                                                            <Col>
                                                                <StatusCell status={location?.locationStatus as ILOCATION_STATUS_MAP_KEYS} />
                                                            </Col>
                                                            <Col>
                                                                <CustomButtonText onClick={() => viewLocation(location)}> 
                                                                    View
                                                                    <ChevronRightSvgIcon className="ml-8" />
                                                                </CustomButtonText>
                                                            </Col> 
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        ))
                                    }
                                </>
                            </If>
                        </div>
                    </div>
                </Col>
            </Row>
            <div id="infowindow-content-layer">
                {popupActive}
            </div>
        </div>
    )
}
