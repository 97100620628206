import { EmptyLabel } from "@components/Utils/EmptyLabel";
import { If } from "@components/Utils/Structural";
import { IProjectDeliverableUI } from "@models/project-deliverable";
import { WHITE_ABSOLUTE } from "@providers/ThemeProvider";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Grid, Col, Divider, Row, Typography, theme } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StatusProjectDeliverableLabel } from "../StatusProjectDeliverableLabel";
import { FileOutlined, HistoryOutlined, MessageOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useBaseProjectTabUrl } from "../../RouteBaseProjectTabs";
import { getProjectDeliverableDetailById, setLastScrollPosition } from "@store/slices/project-deliverables";
import { isNumberGreaterThanZero } from "@shared/util/number-util";
import SvgIconWrapper from '@shared/components/SvgIconWrapper';
import { KeySvgIcon } from '@components/Icons/KeyIcon';
import { useDateFormatter } from "@shared/util/date-utils";

const { useBreakpoint } = Grid;

const { Text } = Typography;

export interface IDeliverableProps {
    keyProp: React.Key;
    deliverable: IProjectDeliverableUI;
}

export const ProjectDeliverableCard = (props: IDeliverableProps) => {

    const { keyProp, deliverable } = props;

    const {
      token: { colorBorder, colorBorderSecondary, colorBgContainer, colorBgLayout, fontSize, colorTextQuaternary, fontSizeHeading5 },
    } = theme.useToken();

    const {
      data: { deliverableSelected },
    } = useAppSelector(state => state.ProjectDeliverables);

    const { t } = useTranslation();

    const { xs, md } = useBreakpoint();

    const dispatch = useAppDispatch();
    const { validBaseUrl } = useBaseProjectTabUrl();
    const navigate = useNavigate();
    let [searchURLParams] = useSearchParams();

    const onSelectDeliverable = (deliverable: IProjectDeliverableUI) => {
      const { id } = deliverable;

      handleLastScrollPosition();

      if (!id) return;
      const searchParams = searchURLParams?.toString() ? `?${searchURLParams?.toString()}` : '';

      navigate(`${validBaseUrl}/deliverables/${id}${searchParams}`, { relative: 'route' });
      dispatch(getProjectDeliverableDetailById({ projectId: 0, projectDeliverableId: String(id) }));
    };

    const handleLastScrollPosition = () => {
      const scrollTop = document.getElementById('containterDeliverableList')?.scrollTop;
      if (isNumberGreaterThanZero(scrollTop)) {
        if (scrollTop! > 1) {
          dispatch(setLastScrollPosition(scrollTop!));
        } else {
          dispatch(setLastScrollPosition(scrollTop!));
        }
      }
    };

    const { dfFormatDatePipe } = useDateFormatter();

    return (
      <div
        onClick={() => onSelectDeliverable(deliverable)}
        key={keyProp}
        className="mb-10 cursor-pointer w-full"
        style={{
          border: `${deliverable?.id === deliverableSelected?.id ? `1.4px solid ${colorBorder}` : `1.2px solid ${colorBorderSecondary}`}`,
          backgroundColor: `${deliverable?.id === deliverableSelected?.id ? `${colorBgLayout}` : `${colorBgContainer}`}`,
          borderRadius: '5px',
        }}
      >
        <div className="relative flex flex-col pt-10 pb-10 pl-15 pr-15" style={{ borderEndStartRadius: '5px', borderEndEndRadius: '5px' }}>
          <If condition={!!deliverable.deliverable?.isKeyMilestone}>
            <IconKeyMilestoneInDeliverableCard />
          </If>
          <Row className="w-full" wrap={false}>
            <Col flex="auto">
              <Text
                className="font-semibold pl-4"
                style={{ fontSize: fontSizeHeading5 }}
                ellipsis={true ? { tooltip: deliverable?.deliverable?.name || <EmptyLabel /> } : false}
              >
                {deliverable?.deliverable?.name || <EmptyLabel />}
              </Text>
            </Col>
            {deliverable.duration !== 1 && (
              <>
                <Col flex="none" className="ml-5">
                  <span className="font-light pr-4" style={{ fontSize: fontSize - 1 }}>
                    {dfFormatDatePipe(deliverable?.mostAccurateStartDate) || <EmptyLabel />}
                  </span>
                </Col>
                <ArrowRightOutlined style={{ width: '9px' }} />
              </>
            )}
            <Col flex="none" className="ml-5">
              <span className="font-light pr-4" style={{ fontSize: fontSize - 1 }}>
                {dfFormatDatePipe(deliverable?.mostAccurateDate) || <EmptyLabel />}
              </span>
            </Col>
          </Row>
          <Row className="flex flex-row items-center">
            <div className="flex flex-row justify-between mt-10 pl-4 pr-4 w-full flex-wrap">
              <div className="flex fle-row font-medium w-max">
                {deliverable && <StatusProjectDeliverableLabel deliverable={deliverable} renderOnEmpty={<EmptyLabel />} />}
              </div>
              <div className="flex flex-row w-max">
                <div className="flex flex-row font-light" style={{ fontSize: fontSize - 1 }}>
                  {!!deliverable?.totalAttachments && (
                    <div className="ml-20">
                      <span className="mr-5">{deliverable?.totalAttachments}</span>
                      <FileOutlined style={{ color: colorTextQuaternary }} />
                    </div>
                  )}
                  {!!deliverable?.totalComments && (
                    <div className="ml-20">
                      <span className="mr-5">{deliverable?.totalComments}</span>
                      <MessageOutlined style={{ color: colorTextQuaternary }} />
                    </div>
                  )}
                </div>
                <If condition={deliverable.durationHasAtLeast1day}>
                  <div className="flex flex-row items-center">
                    <If condition={Boolean(!!deliverable?.totalAttachments || deliverable?.totalComments)}>
                      <Divider type="vertical" className="ml-10" />
                    </If>
                    <span className="text-color-neutral-7">
                      <If condition={Boolean(md)}>
                        <span className="font-semibold">{t('projectDeliverable.duration')}</span>
                      </If>
                      <If condition={Boolean(xs)}>
                        <HistoryOutlined />
                      </If>
                      :{' '}
                      {`${
                        deliverable.hasDuration
                          ? `${deliverable.duration} ${deliverable.duration === 1 ? t('generic.day') : t('generic.days')}`
                          : ''
                      }`}
                    </span>
                  </div>
                </If>
              </div>
            </div>
          </Row>
        </div>
      </div>
    );
}

const IconKeyMilestoneInDeliverableCard = () => {
    const { token: { colorPrimary, fontSizeSM } } = theme.useToken();
    return (
        <SvgIconWrapper backgroundColor={colorPrimary} >
            <KeySvgIcon style={{ color: WHITE_ABSOLUTE, fontSize: fontSizeSM }} />
        </SvgIconWrapper>
    )
}