import { useAuth } from "@providers/AuthProvider";
import { usePreferencesProvider } from "@providers/PreferencesProvider";
import { userSettingsService } from "@services/user-settings.service";
import { Col, Row, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useState } from "react";

export const PreferencesLocalization = () => {
    const { userSettings, setPreferences } = usePreferencesProvider();

    console.log(userSettings)

    const [dateFormat, setDateFormat] = useState<string>(userSettings?.settings?.dateFormat || 'US')
    const [measurementUnit, setMeasurementUnit] = useState<string>(userSettings?.settings?.measurementUnit || 'ft')

    const { user } = useAuth()

    const onChangeDateFormat = async (value: string) => {
        setDateFormat(value);
        if (userSettings && userSettings.id) {
            const userPreferences = await userSettingsService.updateUserPreferences({
              ...userSettings,
              settings: JSON.stringify({ ...userSettings.settings, dateFormat: value })
            })
            setPreferences(userPreferences);
          } 
          
          if (!userSettings) {
            const userPreferences = await userSettingsService.createUserPreferences({
              settings: JSON.stringify({ dateFormat: value }),
              user: { id: user?.id }
            })
            setPreferences(userPreferences);
          }
    }
    const onChangeMeasurementUnit = async (value: string) => {
        setMeasurementUnit(value);
        if (userSettings && userSettings.id) {
            const userPreferences = await userSettingsService.updateUserPreferences({
              ...userSettings,
              settings: JSON.stringify({ ...userSettings.settings, measurementUnit: value })
            })
            setPreferences(userPreferences);
          } 
          
          if (!userSettings) {
            const userPreferences = await userSettingsService.createUserPreferences({
              settings: JSON.stringify({ measurementUnit: value }),
              user: { id: user?.id }
            })
            setPreferences(userPreferences);
          }
    }

    return <div>
        <Row>
            <Col span={24} className="mb-20">
                <FormItem label="Date Format">
                    <Select
                        value={dateFormat}
                        onChange={(value: string) => { onChangeDateFormat(value); }}
                        options={[ { value: 'US', label: 'MM/DD/YYYY' }, { value: 'EU', label: 'YYYY-MM-DD' } ]}
                    />
                </FormItem>
                <FormItem label="Unit of Measurement">
                    <Select
                        value={measurementUnit}
                        onChange={(value: string) => { onChangeMeasurementUnit(value); }}
                        options={[ { value: 'ft', label: 'Feet' }, { value: 'm', label: 'Meters' } ]}
                    />
                </FormItem>
            </Col>
        </Row>
    </div>
};