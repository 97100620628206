/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useLayoutEffect, useState } from "react";
import "@gooddata/sdk-ui-dashboard/styles/css/main.css";
import { Dashboard } from "@gooddata/sdk-ui-dashboard";
import { idRef } from "@gooddata/sdk-model";
import { BackendProvider, WorkspaceProvider } from "@gooddata/sdk-ui";

import "@gooddata/sdk-ui-filters/styles/css/main.css";
import "@gooddata/sdk-ui-charts/styles/css/main.css";
import "@gooddata/sdk-ui-geo/styles/css/main.css";
import "@gooddata/sdk-ui-pivot/styles/css/main.css";
import "@gooddata/sdk-ui-kit/styles/css/main.css";
import "@gooddata/sdk-ui-ext/styles/css/main.css";

import debounce from "lodash/debounce";
import tigerFactory, { TigerJwtAuthProvider, SetJwtCallback } from "@gooddata/sdk-backend-tiger";
import { NotAuthenticated, IAuthenticationContext } from '@gooddata/sdk-backend-spi';
import { http } from "@infrastructure/http/axios.instance";
import { IGoodDataDashboard } from "@models/good-data-dashboard.model";
import { Layout, Tabs, TabsProps, theme } from "antd";
import { MainHeader } from "@components/Layout/HeaderLayout/MainHeader";
import { Content } from "antd/es/layout/layout";
import { useTranslation } from "react-i18next";
import { useLayoutContex } from "@providers/LayoutProvider";

const fetchNewJwt = async () => {
    const { data } = await http.post('/gooddata/jwt', {});
    return data.jwt;
  }

export const GoodDataPage = () => {

  const [initialJwt, setInitialJwt] = useState<string>('');

  const fetchInitialJwt = async () => {
    var jwt = await fetchNewJwt();
    setInitialJwt(jwt);
  }

  useEffect(() => {
    fetchInitialJwt();
  }, []);

  if (initialJwt) {
    return <GoodDataWrapper initialJwt={initialJwt} />
  } else {
    return <></>
  }
};

interface IGoodDataWrapperProps {
    initialJwt: string;
}

const getTabItems: (dashboards: IGoodDataDashboard[]) => TabsProps['items'] = (dashboards: IGoodDataDashboard[]) =>
  dashboards.map(dashboard => ({
    key: String(dashboard.id),
    label: dashboard.tabName,
    children: <GoodDataDashboard goodDataDashboard={dashboard} />,
    className: 'h-full',
  }));

export const GoodDataWrapper = (props: IGoodDataWrapperProps) => {
    const { initialJwt } = props;

    const notAuthenticatedHandler = debounce((context: IAuthenticationContext, error: NotAuthenticated) => {
        console.log('Good data has no auth. See error below.');
    }, 500);
  
    const jwtIsAboutToExpire = async (setJwt: SetJwtCallback) => {
        const jwt = await fetchNewJwt(); // new JWT generated or obtained for the authenticated user if the current session should continue
        setJwt(jwt); // set the JWT back into authentication provider via provided callback
    };
    const secondsBeforeTokenExpirationToCallReminder = 60;
  
    // Setup JWT auth provider.
  
    const jwtAuthProvider = new TigerJwtAuthProvider(
        initialJwt, // initial JWT
        notAuthenticatedHandler,  // See context deferred authentication above about this optional handler argument. Optional argument.
        jwtIsAboutToExpire, // Optional custom `(setJwt: SetJwtCallback) => void` callback called right before the JWT is about to expire.
        secondsBeforeTokenExpirationToCallReminder, // The number of seconds before token expiration to call tokenIsAboutToExpireHandler handler, optional, use 0 or negative number to disable the callback.
    );
  
    // Create a new AnalyticalBackend instance
    const goodDataBackend = tigerFactory(undefined, {
      packageName: "siterise",
      packageVersion: "1",
    })
    .onHostname("https://siterise.cloud.gooddata.com/")
    .withAuthentication(jwtAuthProvider);

    const { setHeaderLabelEntityState } = useLayoutContex();

    const {
        token: { colorBgLayout, colorBgContainer, marginLG },
    } = theme.useToken();

    const { t } = useTranslation();

    const [dashboards, setDashboards] = useState<IGoodDataDashboard[]>([]);

    const loadDashboards = async () => {
        const { data } = await http.get<IGoodDataDashboard[]>('/good-data-dashboards');
        setDashboards(data);
    }

    useEffect(() => {
        loadDashboards();
    }, []);

    useLayoutEffect(() => {
        setHeaderLabelEntityState && setHeaderLabelEntityState(t('generic.dashboard2'));
    }, []);

    return (
        <BackendProvider backend={goodDataBackend}>
            <Layout>
                <MainHeader />
                <Content className="w-full h-full">
                    {dashboards.length > 0 && (
                    <Tabs
                        items={getTabItems(dashboards)}
                        className="custom-tabs imb-0 overflow-hidden"
                        style={{ backgroundColor: colorBgLayout, overflowY: 'auto' }}
                        tabBarStyle={{
                        backgroundColor: colorBgContainer,
                        paddingLeft: `${marginLG}px`,
                        marginBottom: 0,
                        }}
                        destroyInactiveTabPane
                    />
                    )}
                </Content>
            </Layout>
        </BackendProvider>
      );
}

interface IGoodDataDashboardProps {
    goodDataDashboard: IGoodDataDashboard;
}

const GoodDataDashboard = (props: IGoodDataDashboardProps) => {
    const { goodDataDashboard } = props;
    const { dashboardId, workspaceId } = goodDataDashboard;
    const dashboard = idRef(dashboardId);
    return <WorkspaceProvider workspace={workspaceId} >
        <Dashboard dashboard={dashboard} />
    </WorkspaceProvider>;
}