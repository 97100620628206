/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useLayoutEffect, useMemo, createContext } from 'react';
import { useLayoutContex } from '@providers/LayoutProvider';
import useElementSize from '@HOOKs/UseElementSize';
import ProjectsTable from '@components/Projects/ProjectsTable';
import { useTranslation } from 'react-i18next';

interface IProjectContainerProps {
  children: React.ReactNode;
}

export const ProjectsContainerContext = createContext(
  {} as {
    heightContainer: number;
  }
);

export const ProjectsView = ({ children }: IProjectContainerProps) => {
  const [, { height: heightHeaderRow }] = useElementSize();

  const { t } = useTranslation();

  const { layoutSize, setNewStylePropertiesInContentLayout, setHeaderLabelEntityState } = useLayoutContex();

  useEffect(() => {
    setNewStylePropertiesInContentLayout && setNewStylePropertiesInContentLayout();
  }, []);

  useLayoutEffect(() => {
    setHeaderLabelEntityState && setHeaderLabelEntityState(`${t('generic.projects')}`);
    return () => setHeaderLabelEntityState && setHeaderLabelEntityState('');
  }, []);

  const layoutHeightCalculated = useMemo(() => {
    return layoutSize ? layoutSize.layoutHeight - heightHeaderRow : 0;
  }, [layoutSize]);

  const heightContainer = (layoutSize?.layoutHeight || 0) - heightHeaderRow - 0;

  const provider = {
    heightContainer,
  };

  return (
    <ProjectsContainerContext.Provider value={provider}>
      <div
        className="pr-15 pl-15 pb-15 pt-15 overflow-auto"
        style={{ height: layoutHeightCalculated > 0 ? layoutHeightCalculated : 'auto' }}
      >
        {children}
      </div>
    </ProjectsContainerContext.Provider>
  );
};

export const useProjectsContainerContext = () => {
  return useContext(ProjectsContainerContext);
};

export const Projects = () => {
  return (
    <>
      <ProjectsView>
        <ProjectsTable />
      </ProjectsView>
    </>
  );
};
