export interface IUser {
  id?: any;
  login?: string;
  firstName?: string;
  lastName?: string;
  imageUrl?: string | null;
  email?: string;
  activated?: boolean;
  langKey?: string;
  authorities?: string[];
  createdBy?: string;
  lastLogin?: Date | null | string;
  createdDate?: Date | null | string;
  lastModifiedBy?: string;
  lastModifiedDate?: Date | null | string;
  password?: string;
  fullName?: string;
  lastLoginDate?: Date;
}


export interface IUserSettings {
  id: string | number;
  settings: string | IUserPreferences,
  user: IUser
}

export interface IUserPreferences {
  appearance: string,
  notification_placement: string,
  notification_soundId: number,
  dateFormat: string,
  measurementUnit: string
}

export interface IUserExtended {
  id?: number;
  fullName?: string;
}

export const defaultValue: Readonly<IUser> = {
  id: '',
  login: '',
  firstName: '',
  lastName: '',
  email: '',
  activated: true,
  langKey: '',
  authorities: [],
  createdBy: '',
  createdDate: null,
  lastModifiedBy: '',
  lastModifiedDate: null,
  password: '',
};
