import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Checkbox, Drawer, Radio, Space, theme, Cascader } from 'antd';
import { NEUTRAL_2_COLOR } from '@providers/ThemeProvider';
import { useTranslation } from 'react-i18next';
import { DeliverableKey } from './MilestoneTable';

interface IProjectsDisplayOptions {
  openDisplayOptions: boolean;
  onClose: () => void;
  columnsState: { [key: string]: boolean };
  setColumnsState: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  setItemsPerPage: (itemsPerPage: number) => void;
  deliverables?: Map<string, DeliverableKey>;
}

const ProjectDisplayOptions: React.FC<IProjectsDisplayOptions> = ({
  columnsState,
  setColumnsState,
  openDisplayOptions,
  onClose,
  setItemsPerPage,
  deliverables,
}) => {
  const [selectedColumns, setSelectedColumns] = useState({ ...columnsState });
  const [deliverablesDisplay, setDeliverablesDisplay] = useState('all'); // 'all', 'keyMilestone', 'selected'
  const [selectedDeliverables, setSelectedDeliverables] = useState<string[][]>([]);

  const handleCheckboxChange = (checked: boolean, columnName: string) => {
    const updatedColumnsState = { ...selectedColumns, [columnName]: checked };
    setSelectedColumns(updatedColumnsState);
  };

  const getSelectedDeliverableIds = () => {
    if (!deliverables) return {};

    // Create a map of all deliverables set to false initially
    const allDeliverableIds = Array.from(deliverables.keys()).reduce((acc, id) => {
      acc[id] = false;
      return acc;
    }, {} as { [key: string]: boolean });

    let selectedIds: string[] = [];

    switch (deliverablesDisplay) {
      case 'all':
        // Select all deliverable IDs
        selectedIds = Array.from(deliverables.keys());
        selectedIds.push('|deliverables-all');
        break;
      case 'keyMilestone':
        // Select only key milestone deliverable IDs
        selectedIds = Array.from(deliverables.values())
          .filter(deliverable => deliverable.isKeyMilestone)
          .map(deliverable => deliverable.id);
        selectedIds.push('|deliverables-keyMilestone');
        break;
      case 'selected':
        // Select only user-selected deliverable IDs from cascader
        selectedIds = selectedDeliverables.map(path => path[path.length - 1]);
        break;
    }

    // Set selected deliverables to true
    selectedIds.forEach(id => {
      allDeliverableIds[id] = true;
    });

    return allDeliverableIds;
  };

  const handleSave = () => {
    const deliverableIdsState = getSelectedDeliverableIds();

    setColumnsState({
      ...deliverableIdsState,
      ...selectedColumns,
    });

    onClose();
  };

  const {
    token: { colorBorderSecondary, colorPrimary, fontSize },
  } = theme.useToken();

  function camelCaseToString(camelCase) {
    // Use a regular expression to insert a space before all uppercase letters
    let spacedString = camelCase.replace(/([a-z])([A-Z])/g, '$1 $2');

    // Capitalize the first letter of the resulting string
    spacedString = spacedString.charAt(0).toUpperCase() + spacedString.slice(1);

    return spacedString;
  }
  const columnOptions = Object.keys(columnsState).filter(item => item !== 'button' && !item.includes('|'));

  const { t } = useTranslation();

  const handleRadioChange = e => {
    setDeliverablesDisplay(e.target.value);
  };

  const formatDeliverablesForCascader = () => {
    if (!deliverables) return [];

    // Group deliverables by phaseName
    const deliverablesByPhase = new Map();

    // First pass: collect all deliverables for each phase
    Array.from(deliverables.values()).forEach(deliv => {
      const phaseName = deliv.phaseName || 'Unassigned Phase';

      if (!deliverablesByPhase.has(phaseName)) {
        deliverablesByPhase.set(phaseName, []);
      }

      deliverablesByPhase.get(phaseName).push(deliv);
    });

    // Second pass: format for cascader
    return Array.from(deliverablesByPhase.entries()).map(([phase, delivsList]) => ({
      value: phase,
      label: phase,
      children: delivsList.map(deliv => ({
        value: deliv.id,
        label: deliv.name || deliv.id,
      })),
    }));
  };

  return (
    <>
      <Drawer
        title="Display Options"
        placement="right"
        onClose={onClose}
        visible={openDisplayOptions}
        width={500}
        extra={
          <Space>
            <Button type="primary" ghost onClick={onClose}>
              {t('generic.cancel')}
            </Button>
            <Button type="primary" onClick={handleSave}>
              {t('generic.save')}
            </Button>
          </Space>
        }
      >
        {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="mb-20 pl-10">
          <b>Default items about pagination</b>
          <Select
            defaultValue={10}
            style={{ width: 120 }}
            options={defaultPaginationOptions}
            onChange={e => {
              setItemsPerPage(e);
            }}
          />
        </div> */}
        <div
          className="font-medium"
          style={{
            height: '32px',
            color: colorPrimary,
            fontSize: fontSize + 2,
            border: `1px solid ${colorBorderSecondary}`,
            backgroundColor: `${NEUTRAL_2_COLOR}`,
            margin: '10px 0 10px 0',
            borderRadius: '5px',
            padding: '5px 0 0 10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          Default Fields
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {columnOptions.map(column => (
            <Checkbox
              key={column}
              checked={selectedColumns[column]}
              disabled={column === 'name'}
              style={{ borderRadius: '5px' }}
              onChange={e => handleCheckboxChange(e.target.checked, column)}
            >
              {camelCaseToString(column)}
            </Checkbox>
          ))}
        </div>
        {deliverables && (
          <div>
            <div
              className="font-medium"
              style={{
                height: '32px',
                color: colorPrimary,
                fontSize: fontSize + 2,
                border: `1px solid ${colorBorderSecondary}`,
                backgroundColor: `${NEUTRAL_2_COLOR}`,
                margin: '10px 0 10px 0',
                borderRadius: '5px',
                padding: '5px 0 0 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              Deliverables
            </div>

            <Radio.Group onChange={handleRadioChange} value={deliverablesDisplay} style={{ marginBottom: '15px' }}>
              <Space direction="vertical">
                <Radio value="all">All Deliverables</Radio>
                <Radio value="keyMilestone">Key Milestone Only</Radio>
                <Radio value="selected">Selected Deliverables</Radio>
              </Space>
            </Radio.Group>
            {deliverablesDisplay === 'selected' && (
              <div style={{ marginTop: '10px' }}>
                <Cascader
                  options={formatDeliverablesForCascader()}
                  onChange={value => setSelectedDeliverables(value as string[][])}
                  multiple
                  placeholder="Select deliverables"
                  style={{ width: '100%' }}
                />
              </div>
            )}
          </div>
        )}
      </Drawer>
    </>
  );
};

export default ProjectDisplayOptions;
