/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useErrorAnimation } from "@HOOKs/UseErrorAnimation";
import { IDeliverable } from "@models/deliverable.model";
import { isFulfilled } from "@reduxjs/toolkit";
import { asyncLaunchNotification } from "@store/slices/notification";
import { createEntity, updateEntity } from "@store/slices/deliverable";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getDisciplines } from "@store/slices/discipline";
import { getRoles } from "@store/slices/role";
import { getEntities as getCompanyRoles } from "@store/slices/company-roles";
import { Button, Col, ConfigProvider, Drawer, Form, Input, Modal, Row, Select, Space, Switch, theme } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { RichTextEditor } from "@components/Comment/RichTextEditor";
import { COLOR_TEXT_BASE, WHITE, useThemeProvider } from "@providers/ThemeProvider";
import { useTranslation } from "react-i18next";
import { resourcePermissionService } from '@services/resource-permission.service';
import { IResourcePermission } from "@models/resource-permission.model";
interface IDeliverableEditProps {
  open: boolean,
  toogle: () => void,
  onModifiedChanges?: (group: IDeliverable) => void,
  deliverable: IDeliverable
}

export const DeliverableEdit = (props: IDeliverableEditProps) => {

    const { open, toogle, deliverable, onModifiedChanges } = props;

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { token: { colorBorderSecondary, paddingSM } } = theme.useToken();

    const [form] = Form.useForm();

    const { confirm } = Modal;

    const { themeConfig } = useThemeProvider();

    const loadDisciplines = () => {
        dispatch(
          getDisciplines()
        );
      }
    
      useEffect(() => {
        loadDisciplines();
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
    const { entities: disciplines } = useAppSelector((state) => state.Discipline);

    const disciplineOptions = useMemo(() => {
        return disciplines.map((d) => {
            return { label: d.name, value: d.id }
        });
    }, [disciplines]);

    const loadRoles = () => {
        dispatch(
          getRoles()
        );
        dispatch(
            getCompanyRoles({})
        );
      }
    
      useEffect(() => {
        loadRoles();
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    const loadResourcePermissions = async () => {
        const response = await resourcePermissionService.getResourcePermissions();
        setResourcePermissions(response.data);
    }

    // eslint-disable-next-line
    const [resourcePermissions, setResourcePermissions] = useState<IResourcePermission[]>([]);
    useEffect(() => {
        loadResourcePermissions();
    }, []);
    
    const { entities: roles } = useAppSelector((state) => state.Roles);
    const { entities: companyRoles } = useAppSelector((state) => state.CompanyRoles);

    const roleOptions = useMemo(() => {
        return roles.map((d) => {
            return { label: d.name, value: d.id }
        });
    }, [roles]);

    const companyRoleOptions = useMemo(() => {
        return companyRoles.map((d) => {
            return { label: d.description, value: d.id }
        });
    }, [companyRoles]);

    // eslint-disable-next-line
    const isKeyMilestone = Form.useWatch('isKeyMilestone', form);
    // eslint-disable-next-line
    const isOpeningMilestone = Form.useWatch('isOpeningMilestone', form);
    // eslint-disable-next-line
    const accessRestricted = Form.useWatch('accessRestricted', form);

    const { loading, updating } = useAppSelector((state) => state.Deliverables);

    const [animateError, playAnimationOfError] = useErrorAnimation();

    const onFinishFailed = (errorInfo: any) => playAnimationOfError();

    const onFinish = (entity: any): void => {
        const hasStatus = deliverable?.id;
        
        if (hasStatus) {
            dispatch(
                updateEntity({
                    ...deliverable,
                    ...entity,
                    discipline: {
                        id: entity.disciplineId
                    },
                    role: {
                        id: entity.roleId
                    },
                    companyRole: entity.companyRoleId ? { id: entity.companyRoleId } : null
                })  
            ).then(onSuccessUpdatedStatus);
            
        } else {
            dispatch(createEntity({
                id: "",
                ...entity,
                discipline: {
                    id: entity.disciplineId
                },
                role: {
                    id: entity.roleId
                },
                companyRole: entity.companyRoleId ? { id: entity.companyRoleId } : null
            })).then(onSuccessCreateStatus);
        }
    };

    const onSuccessUpdatedStatus = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: `Changes saved`,
                  description: `Your changes have been saved successfully`
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onSuccessCreateStatus = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: `Changes saved`,
                  description: `Deliverable created successfully`
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onClose = () => { 
        form.resetFields();
        toogle(); 
    }

    const onSubmit = () => form.submit();

    useEffect(() => { setDeliverableFormFields() }, [deliverable])

    const setDeliverableFormFields = () => {
        form.setFieldsValue({
            ...deliverable,
            disciplineId: deliverable?.discipline?.id,
            roleId: deliverable?.role?.id,
            companyRoleId: deliverable.companyRole?.id
        });
    }
    return (
        <>
            <Drawer 
                width={448} 
                title={`${deliverable?.id ? "Edit": "Add"} deliverable`}
                placement="right" 
                onClose={() => {
                    confirm({
                        title: "If you close this drawer, your changes won't be saved",
                        icon: <ExclamationCircleFilled />,
                        content: ``,
                        onOk() {
                            onClose();
                        }
                    });
                }} 
                open={open}
                closable={true}
                extra={
                    <Space>
                      <Button type="primary" ghost onClick={onClose}>
                        Cancel
                      </Button>
                      <Button loading={loading} onClick={onSubmit} type="primary" disabled={updating}>
                        {`${deliverable?.id ? "Save": "Create"}`}
                      </Button>
                    </Space>
                  }
            >
                <Form
                    layout="vertical"
                    form={form}
                    onSubmitCapture={(e) => { e.stopPropagation(); e.preventDefault(); }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className={`${animateError && "error-animation"}`}
                >

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[
                                    { required: true, message: "Name field is Required" },
                                ]}
                            >
                                <Input placeholder="Name" allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={24} className='pl-10 pr-10'>
                            <Form.Item name="infoGuide" label="Info Guide" rules={[
                                { required: false }
                            ]}>
                                {open && <ConfigProvider theme={{ token: { colorTextBase: themeConfig.darkMode ? WHITE : COLOR_TEXT_BASE } }}>
                                    <RichTextEditor 
                                        showSend={false} 
                                        placeholderText='Write the info guide here....' 
                                        defaultValue={deliverable?.infoGuide} 
                                        onChange={(value: string) => {
                                            form.setFieldValue("infoGuide", value);
                                        }}
                                        mentionsDisabled={true} />
                                </ConfigProvider>}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item 
                                name="disciplineId" 
                                label="Discipline"
                                rules={[
                                    { required: true, message: "Discipline field is Required" },
                                ]}
                            >
                                <Select
                                    placeholder="Select Discipline"
                                    options={disciplineOptions}
                                />
                            </Form.Item>

                            <Form.Item 
                                name="roleId" 
                                label="Role"
                                rules={[
                                    { required: true, message: "Role field is Required" },
                                ]}
                            >
                                <Select
                                    placeholder="Select Role"
                                    options={roleOptions}
                                />
                            </Form.Item>

                            <Form.Item 
                                name="companyRoleId" 
                                label={t('admin.companyRole')}
                                rules={[
                                    { required: false },
                                ]}
                            >
                                <Select
                                    placeholder={t('admin.selectCompanyRole')}
                                    options={companyRoleOptions}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{ borderTop: `1px solid ${colorBorderSecondary}`, borderBottom: `1px solid ${colorBorderSecondary}`, paddingTop: paddingSM }}>
                        <Col span={"none"} className="pr-0" style={{  }}>
                            Set as Key Milestone
                        </Col>
                        <Col span={"none"} className="pr-0" style={{ flex: '1', textAlign: 'right' }}>
                            <Form.Item name="isKeyMilestone" valuePropName="checked">
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16} style={{ borderTop: `1px solid ${colorBorderSecondary}`, borderBottom: `1px solid ${colorBorderSecondary}`, paddingTop: paddingSM }}>
                        <Col span={"none"} className="pr-0" style={{  }}>
                            Set as Opening Milestone
                        </Col>
                        <Col span={"none"} className="pr-0" style={{ flex: '1', textAlign: 'right' }}>
                            <Form.Item name="isOpeningMilestone" valuePropName="checked">
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{ borderTop: `1px solid ${colorBorderSecondary}`, borderBottom: `1px solid ${colorBorderSecondary}`, paddingTop: paddingSM }}>
                        <Col span={"none"} className="pr-0" style={{  }}>
                            Access Restricted
                        </Col>
                        <Col span={"none"} className="pr-0" style={{ flex: '1', textAlign: 'right' }}>
                            <Form.Item name="accessRestricted" valuePropName="checked">
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
  );
}