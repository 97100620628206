import { IDeliverable } from "@models/deliverable.model";
import { getEntities as getResourcePermissions, deleteEntity, createEntity } from "@store/slices/resource-permission";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Button, Col, Drawer, Form, Row, Select, Table, theme } from "antd"
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getEntities as getCompanyRoles } from "@store/slices/company-roles";
import { IResourcePermission } from "@models/resource-permission.model";
import { EmptyCellTable } from "@components/Utils/EmptyCellTable";
import { DeleteOutlined } from "@ant-design/icons";
import { getUsers } from "@store/slices/users-extended";
import { getRoles } from "@store/slices/role";
import { getEntities as getGroups } from "@store/slices/groups";

interface IDeliverablePermissionsEditProps {
    open: boolean,
    toogle: () => void,
    onModifiedChanges?: (group: IDeliverable) => void,
    deliverable: IDeliverable
  }

export const DeliverablePermissionsEdit = (props: IDeliverablePermissionsEditProps) => {

    const { open, toogle, deliverable } = props;

    const [selectedPermissionType, setSelectedPermissionType] = useState<string>('COMPANY_ROLE');

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const {
        token: { colorTextTertiary },
      } = theme.useToken();

    const { entities: companyRoles } = useAppSelector((state) => state.CompanyRoles);
    const { usersList } = useAppSelector((state) => state.UsersExtended);
    const { entities: roles } = useAppSelector((state) => state.Roles);
    const { entities: groups } = useAppSelector((state) => state.Groups);
    const { entities: resourcePermissions } = useAppSelector((state) => state.ResourcePermissions);

    const [form] = Form.useForm();
    const companyRole = Form.useWatch('companyRole', form);
    const role = Form.useWatch('role', form);
    const user = Form.useWatch('user', form);
    const group = Form.useWatch('group', form);

    const resourcePermissionsFiltered = useMemo(() => {
        return resourcePermissions.filter(x => x.deliverable?.id === deliverable.id);
    }, [resourcePermissions, deliverable.id]);

    const onClose = () => { 
        form.resetFields();
        toogle(); 
    }

    
    const loadEntities = () => {
        dispatch(getResourcePermissions());
        dispatch(getUsers());
        dispatch(getRoles());
        dispatch(getCompanyRoles({}));
        dispatch(getGroups({}));
      }
    
    useEffect(() => {
        loadEntities();
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliverable]);

    const deletePermission = (permission: IResourcePermission) => {
        if (permission?.id) {
            dispatch(deleteEntity({id: permission?.id})).then(() => {
                loadEntities();
              });
        }
    }

    const companyRoleOptions = useMemo(() => {
        return companyRoles.map((d) => {
            return { label: d.description, value: d.id }
        });
    }, [companyRoles]);

    const userOptions = usersList.map(elem => {
        return { label: elem.fullName, value: elem.id }
    })
    const roleOptions = roles.map(elem => {
        return { label: elem.name, value: elem.id }
    })
    const groupOptions = groups.map(elem => {
        return { label: elem.name, value: elem.id }
    })

    const addPermission = () => {
        const newEnt = { deliverable: { id: deliverable.id } };
        if (selectedPermissionType === 'COMPANY_ROLE' && companyRole) {
            // @ts-ignore
            newEnt.companyRole = { id: companyRole };
        } else if (selectedPermissionType === 'USER' && user) {
            // @ts-ignore
            newEnt.user = { id: user };
        } else if (selectedPermissionType === 'GROUP' && group) {
            // @ts-ignore
            newEnt.group = { id: group };
        } else if (selectedPermissionType === 'ROLE' && role) {
            // @ts-ignore
            newEnt.role = { id: role };
        } else {
            return;
        }
        dispatch(createEntity(newEnt));
    }

    const getTypeLabel = (resourcePermission: IResourcePermission) => {
        if (resourcePermission.companyRole) {
            return 'Company Role';
        } else if (resourcePermission.role) {
            return 'Role';
        } else if (resourcePermission.user) {
            return 'User';
        } else if (resourcePermission.group) {
            return 'Group';
        }
        return 'n/a'
    }

    const getTypeName = (resourcePermission: IResourcePermission) => {
        if (resourcePermission.companyRole) {
            return resourcePermission?.companyRole?.description;
        } else if (resourcePermission.role) {
            return resourcePermission?.role?.name;
        } else if (resourcePermission.user) {
            return resourcePermission?.user?.login;
        } else if (resourcePermission.group) {
            return resourcePermission?.group?.name;
        }
        return 'n/a'
    }

    const columns = [
        {
            title: 'Resource',
            render: (resourcePermission: IResourcePermission) => { return <span style={{ color: colorTextTertiary }}>{getTypeName(resourcePermission)}</span> || <EmptyCellTable/> },
            ellipsis: true,
        },
        {
            title: 'Type',
            render: (resourcePermission: IResourcePermission) => { return <span style={{ color: colorTextTertiary }}>{getTypeLabel(resourcePermission)}</span> || <EmptyCellTable/> },
            ellipsis: true,
        },
        {
            title: '',
            width: "100px",
            render: (resourcePermission: IResourcePermission) => { return (
                <Row>
                    <Col>
                        <div 
                            onClick={() => deletePermission(resourcePermission)}
                            style={{ color: '#ff4d4f', width: '120px' }}
                        >
                            <DeleteOutlined className="mr-8 scale-90" />
                            <span>Remove</span>
                        </div>
                    </Col>
                </Row>
            ) },
        }
    ];

    const permissionTypeOptions = [
        { label: 'User', value: 'USER' },
        { label: 'Role', value: 'ROLE' },
        { label: 'Company Role', value: 'COMPANY_ROLE' },
        { label: 'Group', value: 'GROUP' }
      ];

    return <>
        <Drawer 
            width={448} 
            title={`Edit Permissions: ${deliverable?.name}`}
            placement="right" 
            open={open}
            closable={true}
            onClose={onClose}
        >
            <Form
                layout="vertical"
                form={form}
                onSubmitCapture={(e) => { e.stopPropagation(); e.preventDefault(); }}
            >
                <Row className="flex flex-items items-center w-full space-between" gutter={12}>
                    <Col span={12}>
                        <Form.Item name="newPermissionType" label="Type">
                            <Select
                                defaultValue={'COMPANY_ROLE'}
                                value={selectedPermissionType}
                                onChange={(value: string) => { setSelectedPermissionType(value); }}
                                options={permissionTypeOptions}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        {selectedPermissionType === 'COMPANY_ROLE' &&<Form.Item 
                            name="companyRole" 
                            label={t('admin.companyRole')}
                            rules={[
                                { required: false },
                            ]}>
                            <Select
                                placeholder={t('admin.selectCompanyRole')}
                                options={companyRoleOptions}
                                allowClear
                            />
                        </Form.Item>}
                        {selectedPermissionType === 'ROLE' && <Form.Item 
                            name="role"
                            label="Select from list"
                            rules={[
                                { required: false },
                            ]}>
                            <Select
                                placeholder="Select Role"
                                options={roleOptions}
                                allowClear
                            />
                        </Form.Item>}
                        {selectedPermissionType === 'USER' && <Form.Item 
                            name="user"
                            label="Select from list"
                            rules={[
                                { required: false },
                            ]}>
                            <Select
                                placeholder="Select User"
                                options={userOptions}
                                allowClear
                            />
                        </Form.Item>}
                        {selectedPermissionType === 'GROUP' && <Form.Item 
                            name="group"
                            label="Select from list"
                            rules={[
                                { required: false },
                            ]}>
                            <Select
                                placeholder="Select Group"
                                options={groupOptions}
                                allowClear
                            />
                        </Form.Item>}
                    </Col>
                </Row>
                <Row className="w-full">
                    <Col span={24} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button type="primary" htmlType="button" onClick={addPermission}>
                            Add permission
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Table dataSource={resourcePermissionsFiltered} columns={columns} />
                </Row>
            </Form>
        </Drawer>
    </>
}