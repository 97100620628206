/* eslint-disable react-hooks/exhaustive-deps */
import { ColorizedText } from "@components/Utils/ColorizedText";
import { EmptyLabelText } from "@components/Utils/EmptyLabel";
import { IChangeOrderResume } from "@models/change-order.model";
import { ChangeOrdersStatusDescription, ChangeOrdersStatusIds } from "@models/enumerations/change-orders-status";
import { OnRowEvent } from "@models/utils/table.utils.model";
import { useDateFormatter } from "@shared/util/date-utils";
import { richCommentJsonToPlainText } from "@shared/util/rich-comment-utils";
import { getEntities } from "@store/slices/change-orders";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Table, theme } from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import { ColumnFilterItem } from "antd/es/table/interface";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ValidUntil } from "../shared/ChangeOrderValidUntil";
import { CostCell } from "./CostCell";
import { InfoIcon } from "./InfoIcon";

export interface IChangeOrdersTableProps {
    showOnlyOpen?: boolean,
}

export const ChangeOrdersTable = (props: IChangeOrdersTableProps) => {
    const { showOnlyOpen } = props;

    const { token: { colorBorderSecondary } } = theme.useToken();

    const { projectId } = useParams<"projectId">();
    const { dfFormatDatePipe } = useDateFormatter();

    const { entities: changeOrdersList, totalItems, loading } = useAppSelector((state) => state.ChangeOrder);
    const dispatch = useAppDispatch();
    const [tableParams, setTableParams] = useState({
        size: 25,
        current: 0,
        sort: `number,asc`,
        projectId,
        openState: showOnlyOpen
    })
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const filterChangeOrders = () => {
        const { current, size, sort } = tableParams;
        dispatch(
            getEntities({
                projectId: projectId!,
                page: current,
                size: size,
                sort: sort,
                statuses: statusFilter()
            })
        );
    }

    useEffect(() => {
        if (projectId) {
            setTableParams((oldValue) => {
                return {
                    ...oldValue,
                    projectId: projectId
                }
            })
        }
    }, [projectId]);

    useEffect(() => {
        filterChangeOrders();
    }, [tableParams]);

    const statusFilter = (): number[] => {
        if (tableParams.openState === true) {
            return [ChangeOrdersStatusIds.PENDING_PUBLISHING, ChangeOrdersStatusIds.PENDING_DETAIL_SUBMISSION, ChangeOrdersStatusIds.PENDING_FINAL_REVIEW, ChangeOrdersStatusIds.PENDING_INITIAL_REVIEW, ChangeOrdersStatusIds.PENDING_ESTIMATE_RESUBMISSION];
        } else {
            return [];
        }
    }

    useEffect(() => {
        setTableParams((oldValue) => {
            return {
                ...oldValue,
                current: 0,
                openState: showOnlyOpen
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOnlyOpen]);

    const onChange: TableProps<IChangeOrderResume>['onChange'] = (pagination, filters, sorter, extra) => {
        setTableParams((oldValue) => {
            return {
                ...oldValue,
                ...pagination?.pageSize && { size: pagination.pageSize },
                current: pagination.current !== undefined ? pagination.current - 1 : 0
            }
        })
    };

    const TableComponentWrapper = (node: any) => {
        const style = { border: `1px solid ${colorBorderSecondary}`, borderRadius: 4 }
        return (
            <table style={{ ...node.style, ...style }}>
                {node.children.map((child: any) => child)}
            </table>
        );
    }

    const onRowClick = (event: OnRowEvent<IChangeOrderResume>) => {
        const { record } = event;
        navigate(`${pathname}/${record.id}`);
    }

    const columns: ColumnsType<IChangeOrderResume> = [
        {
            title: <div className="text-center">#CO</div>,
            dataIndex: 'number',
            className: ' text-center',
            sorter: ({ id: a }, { id: b }) => Number(a) - Number(b),
            defaultSortOrder: 'ascend',
            width: 60,
        },
        {
            title: 'Submitted',
            dataIndex: 'createdDate',
            ellipsis: true,
            render: (value: string) => { return dfFormatDatePipe(value) },
        },
        {
            title: 'Valid until',
            dataIndex: 'dueDate',
            ellipsis: true,
            render: (value: string) => { return value ? <ValidUntil date={value} /> : <EmptyLabelText /> },
        },
        {
            title: 'Description',
            dataIndex: 'description',
            render: (value: string) => { return value ? richCommentJsonToPlainText(value) : <EmptyLabelText /> },
            ellipsis: true,
            width: '30%',
        },
        {
            title: <div className="text-center">Total Cost <InfoIcon tooltip="Default currency: example" /></div>,
            className: ' text-right',
            render: (changeOrder: IChangeOrderResume) => <CostCell cost={changeOrder.totalCost} currency={changeOrder.currency} />,
            sorter: ({ id: a }, { id: b }) => Number(a) - Number(b),
        },
        {
            title: 'Submitted by',
            render: (changeOrder: IChangeOrderResume) => { return getCreatedByLabel(changeOrder) },
            width: 200,
        },
    
        {
            title: "Status",
            render: (changeOrder: IChangeOrderResume) => { return (changeOrder.lastStatusTransition?.status?.id && changeOrder.lastStatusTransition?.status?.name) ? <ColorizedText mapKey={changeOrder.lastStatusTransition.status.id.toString()} text={changeOrder.lastStatusTransition.status.name} colorMap={statusMap()} /> : <EmptyLabelText /> },
            filters: statusFilters,
            onFilter: (value, record) => record.lastStatusTransition?.status?.name?.indexOf(value.toString()) === 0,
        },
    ];

    return (
        <>
            <Table
                loading={loading}
                rowKey="id"
                size="small"
                columns={columns}
                dataSource={changeOrdersList}
                onChange={onChange}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => onRowClick({ event, record, rowIndex })
                    };
                }}
                pagination={{ current: tableParams.current + 1, pageSize: tableParams.size, total: totalItems }}
                components={{
                    table: (node: any) => TableComponentWrapper(node)
                }}
                rowClassName="cursor-pointer"
            />
        </>
    );
};

const statusMap = () => {
    return new Map([
        [ChangeOrdersStatusIds.PENDING_PUBLISHING.toString(), "#FA8C16"],
        [ChangeOrdersStatusIds.PENDING_INITIAL_REVIEW.toString(), "#FA8C16"],
        [ChangeOrdersStatusIds.PENDING_ESTIMATE_RESUBMISSION.toString(), "#FA8C16"],
        [ChangeOrdersStatusIds.PENDING_DETAIL_SUBMISSION.toString(), "#FA8C16"],
        [ChangeOrdersStatusIds.PENDING_FINAL_REVIEW.toString(), "#FA8C16"],
        [ChangeOrdersStatusIds.APPROVED.toString(), "#237804"],
        [ChangeOrdersStatusIds.PENDING_DETAIL_RESUBMISSION.toString(), "#FA8C16"],
        [ChangeOrdersStatusIds.REJECTED.toString(), "#A8071A"],
        [ChangeOrdersStatusIds.CANCELED.toString(), "#A8071A"]
    ]);
}

const statusFilters: ColumnFilterItem[] = [
    {
        text: ChangeOrdersStatusDescription.PENDING_PUBLISHING,
        value: ChangeOrdersStatusDescription.PENDING_PUBLISHING,
    },
    {
        text: ChangeOrdersStatusDescription.PENDING_INITIAL_REVIEW,
        value: ChangeOrdersStatusDescription.PENDING_INITIAL_REVIEW,
    },
    {
        text: ChangeOrdersStatusDescription.PENDING_ESTIMATE_RESUBMISSION,
        value: ChangeOrdersStatusDescription.PENDING_ESTIMATE_RESUBMISSION,
    },
    {
        text: ChangeOrdersStatusDescription.PENDING_DETAIL_SUBMISSION,
        value: ChangeOrdersStatusDescription.PENDING_DETAIL_SUBMISSION,
    },
    {
        text: ChangeOrdersStatusDescription.PENDING_FINAL_REVIEW,
        value: ChangeOrdersStatusDescription.PENDING_FINAL_REVIEW,
    },
    {
        text: ChangeOrdersStatusDescription.APPROVED,
        value: ChangeOrdersStatusDescription.APPROVED,
    },
    {
        text: ChangeOrdersStatusDescription.PENDING_DETAIL_RESUBMISSION,
        value: ChangeOrdersStatusDescription.PENDING_DETAIL_RESUBMISSION,
    },
    {
        text: ChangeOrdersStatusDescription.REJECTED,
        value: ChangeOrdersStatusDescription.REJECTED,
    },
    {
        text: ChangeOrdersStatusDescription.CANCELED,
        value: ChangeOrdersStatusDescription.CANCELED,
    }
];

const getCreatedByLabel = (changeOrder: IChangeOrderResume): String => {
    if (changeOrder?.createdByFirstName && changeOrder?.createdByLastName) {
        return changeOrder.createdByFirstName + ' ' + changeOrder.createdByLastName;
    } else if (changeOrder?.createdByFirstName) {
        return changeOrder.createdByFirstName;
    } else if (changeOrder?.createdByLastName) {
        return changeOrder.createdByLastName;
    } else {
        return changeOrder?.createdBy?.toString() || '';
    }
}