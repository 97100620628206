import { IDeliverable } from './deliverable.model';
import { IPhase } from './phase.model';
import { IProjectDeliverableApproval } from './project-deliverable-approval';
import { IProject } from './project-model';
import { IUser } from './user.model';

export interface IProjectDeliverable {
  id?: number;
  order?: number | null;
  duration?: number | null;
  targetStartDate?: string | null;
  targetFinishDate?: string | null;
  plannedStartDate?: string | null;
  plannedFinishDate?: string | null;
  actualStartDate?: string | null;
  notApplicableFlag?: boolean | null;
  completedFlag?: boolean | null;
  atRiskFlag?: boolean | null;
  dependencyOffset?: number | null;
  actualFinishDate?: string | null;
  phase?: IPhase | null;
  deliverable?: IDeliverable | null;
  project?: IProject | null;
  dependency?: IProjectDeliverable | null;
  owner?: string;
  totalAttachments?: number;
  totalComments?: number;
  lastModifiedBy?: number;
  lastModifiedByEmail?: string;
  lastModifiedByFullName?: string;
  lastModifiedDate?: string;

  // Extra Props
  status?: string | null;
  approvals?: IProjectDeliverableApproval[];
}
export interface IProjectDeliverableStartEndDates {
  undoActualFinishDate?: boolean | null;
  undoActualStartDate?: boolean | null;
}
export interface IProjectDeliverableUI extends IProjectDeliverable {
  isStatusPendingApproval: boolean;
  isStatusCompleted: boolean;
  hasUserErrorPolicy: boolean;
  isWaitingForApprovals: boolean;
  statusLabelUI: string;
  totalApprovers: number;
  firstApproval?: IProjectDeliverableApproval;
  lastApproval?: IProjectDeliverableApproval;
  approval: IProjectDeliverableApproval;
  nextApproval?: IProjectDeliverableApproval;
  approverUser: IUser | null;
  approverUserFullName: string;
  awaitingApprovalFrom: string;
  requestedOn: string;
  approverNumber: number;
  hasDuration: boolean;
  durationHasAtLeast1day: boolean;
  diffPlannedAndActualStartDate: number | null;
  diffPlannedAndActualEndDate: number | null;
  plannedStartDateFormatted: string;
  plannedFinishDateFormatted: string;
  actualStartDateFormatted: string;
  actualFinishDateFormatted: string;
  diffAbsolutePlannedAndActualStartDate: number | null;
  diffAbsolutePlannedAndActualEndDate: number | null;
  mostAccurateDate: string
  mostAccurateStartDate: string
}

export interface ProjectDeliverableTimeLine {
  projectDeliverableId?: string;
  projectDeliverableName?: string;
  plannedStartDate: DateDiff;
  plannedFinishDate: DateDiff;
  projectDeliverableChildren: ProjectDeliverableTimeLine[]
}
export interface ProjectDeliverableTimeLineAndReasonRequired {
  timeline: ProjectDeliverableTimeLine[],
  reasonRequired: boolean
}

export interface ProjectDeliverableTimeLineResponse {
  projectDeliverable?: ProjectDeliverableTimeLine;
}
export interface DateDiff {
  newDate: string,
  oldDate: string,
  diff: string
}

export enum IMPACT {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  EQUAL = 'EQUAL'
}

export const defaultValue: Readonly<IProjectDeliverable> = {
  notApplicableFlag: false,
  completedFlag: false,
};
