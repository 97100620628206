
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { resourcePermissionService } from '@services/resource-permission.service';
import { serializeAxiosError } from "../reducer.util";
import { cleanEntity } from '@shared/util/entity-utils';
import { IResourcePermission } from '@models/resource-permission.model';
import { asyncLaunchNotification } from './notification';

export interface IDeleteResourcePermission {
  id: string | number;
  onSuccess?: () => void;
}

export const getEntities = createAsyncThunk(
    'resourcePermission/fetch_entity_list',
    async () => {
        return resourcePermissionService.getResourcePermissions();
    },
    { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
    `resourcePermission/create_entity`,
    async (entity: IResourcePermission, thunkAPI) => {
      const result = await resourcePermissionService.createResourcePermission(cleanEntity(entity));
      thunkAPI.dispatch(getEntities());
      return result;
    },
    { serializeError: serializeAxiosError }
  );

export const deleteEntity = createAsyncThunk(
    `resourcePermission/delete_entity`,
    async (payload: IDeleteResourcePermission, thunkAPI) => {
      const result = await resourcePermissionService.deleteResourcePermission(payload.id).then(() => {
        thunkAPI.dispatch(asyncLaunchNotification({
          type: "success",
          config: {
            message: `Permission deleted`,
            description: `Permission deleted successfully`
          }
        }));
        payload?.onSuccess && payload.onSuccess();
      });
      return result;
    },
    { serializeError: serializeAxiosError }
  );
  
interface IResourcePermissionState {
    loading: boolean;
    errorMessage: string | null;
    entities: ReadonlyArray<IResourcePermission>;
}

const initialState: IResourcePermissionState = {
    loading: false,
    errorMessage: null,
    entities: []
};

export const ResourcePermissionSlice = createSlice({
    name: 'resourcePermissions',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const { data } = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data
                };
            })
            .addMatcher(isPending(getEntities), state => {
                state.errorMessage = null;
                state.loading = true;
            })
            .addMatcher(isFulfilled(createEntity, deleteEntity), (state) => {
                state.loading = false;
            })
            .addMatcher(isPending(createEntity, deleteEntity), state => {
              state.errorMessage = null;
            });
    }
});

// Reducer
export default ResourcePermissionSlice.reducer;

