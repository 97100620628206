import { Table, theme, Progress } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { IProject } from '@models/project-model';
import { IProjectStatus } from '@models/project-status.model';
import { ISite } from '@models/site.model';
import { EmptyCellTable } from '@components/Utils/EmptyCellTable';
import { ChevronRightSvgIcon } from '@components/Icons/ChevronRightSvgIcon';
import { SorterResult, SortOrder } from 'antd/es/table/interface';
import { useEffect, useState } from 'react';
import React, { forwardRef, useImperativeHandle } from 'react';
import ProjectDisplayOptions from './ProjectDisplayOptions';

interface MilestoneTableProps {
  projectList: IProject[];
  loading: boolean;
  statusFilters: { text: string; value: string }[];
  heightContainer: number;
  setSortingKey: (key: string) => void;
  setSortingOrder: (order: string) => void;
  setPageNumber: (pageNumber: number) => void;
  setItemsPerPage: (itemsPerPage: number) => void;
  sortingOrder: string;
  navigateToProjectDetails: (record: IProject) => void;
  selectedTemplate: string;
}

const initialColumnsState: { [key: string]: boolean } = {
  id: true,
  projectStatus: true,
  location: true,
  progress: true,
  managerName: true,
  button: true,
};

export interface DeliverableKey {
  id: string;
  name: string;
  discipline: string;
  isKeyMilestone: boolean;
  phaseName: string;
}

export const MilestoneTable = forwardRef<unknown, MilestoneTableProps>(
  (
    {
      selectedTemplate,
      projectList,
      loading,
      statusFilters,
      heightContainer,
      setSortingKey,
      setSortingOrder,
      setPageNumber,
      setItemsPerPage,
      sortingOrder,
      navigateToProjectDetails,
    },
    ref
  ) => {
    const [openDisplayOptions, setOpenDisplayOptions] = useState(false);
    const [columnsState, setColumnsState] = useState(initialColumnsState);
    const [allDeliverables, setAllDeliverables] = useState<Map<string, DeliverableKey>>(new Map());

    const onClose = () => {
      setOpenDisplayOptions(false);
    };

    const {
      token: { colorBorderSecondary, colorPrimaryText },
    } = theme.useToken();

    const [enrichedProjectList, setEnrichedProjectList] = useState<IProject[]>([]);

    const buildProjectStatus = (status: IProjectStatus) => {
      if (status && status.id && status.name) return <>{status.name}</>;
      return <EmptyCellTable />;
    };

    const ViewMoreCell = () => {
      return (
        <div className="flex flex-row items-center select-none">
          <span className="ml-10" style={{ color: colorPrimaryText }}>
            View
          </span>
          <ChevronRightSvgIcon className="ml-10" style={{ color: colorPrimaryText }} />
        </div>
      );
    };

    const getStatusBackgroundColor = (status: string): string => {
      if (status === 'At Risk') {
        return '#FFFBE6'; // light yellow
      } else if (status.includes('days late')) {
        return '#FFF1F0'; // light red
      }
      return '';
    };

    const columns: ColumnsType<IProject> = [
      {
        title: 'Project ID',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        render: value => {
          return value || <EmptyCellTable />;
        },
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: true,
        fixed: 'left',
      },
      {
        title: 'Location',
        dataIndex: 'site.location.name',
        key: 'location',
        width: 150,
        render: (site: ISite, record: any) => record.site?.location?.name || <EmptyCellTable />,
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: true,
        fixed: 'left',
      },
      {
        title: 'Status',
        dataIndex: 'projectStatus',
        key: 'projectStatus',
        width: 150,
        render: (status: IProjectStatus) => buildProjectStatus(status),
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: true,
        filters: statusFilters,
        onFilter: (value, record) => record.projectStatus?.name === value,
      },
      {
        title: 'Progress',
        dataIndex: 'progress',
        key: 'progress',
        width: 150,
        render: (progress: number) => (
          <Progress percent={progress} size="small" status={progress === 100 ? 'success' : 'active'} format={percent => `${percent}%`} />
        ),
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: true,
      },
      {
        title: 'Manager',
        dataIndex: 'managerName',
        key: 'managerName',
        width: 150,
        render: (managerName: string) => managerName || <EmptyCellTable />,
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: true,
      },
      {
        title: 'Deliverables',
        dataIndex: 'deliverables',
        key: 'deliverables',
        render: () => 'Deliverables here',
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: true,
      },
      {
        title: '',
        dataIndex: 'button',
        key: 'button',
        width: 100,
        render: (text, record: IProject) => (
          <div onClick={() => navigateToProjectDetails(record)} className="cursor-pointer">
            <ViewMoreCell />
          </div>
        ),
      },
    ];

    const [visibleColumns, setVisibleColumns] = useState(columns.filter(column => column?.key && columnsState[String(column.key)]));

    useEffect(() => {
      if (!projectList || projectList.length === 0) return;

      let filteredProjects = [...projectList];

      // Apply template filter if one is selected
      if (selectedTemplate) {
        filteredProjects = filteredProjects.filter(project => project.template?.name === selectedTemplate);
      }

      // Continue with the existing enrichment logic using filteredProjects instead of projectList
      const deliverablesEnrichedProjectList = filteredProjects.map(project1 => {
        const project: any = project1;
        if (!project.projectDeliverableList || project.projectDeliverableList.length === 0) {
          return project;
        }

        const processedDeliverables = project.projectDeliverableList.map(deliverable => {
          // Extract required properties
          const deliverableName = deliverable.deliverable?.name || 'Unnamed';
          const phaseName = deliverable.phase?.name || 'Unknown Phase';
          const isKeyMilestone = deliverable.deliverable?.isKeyMilestone || false;
          const discipline = deliverable.deliverable?.discipline?.name || 'N/A';
          const atRiskFlag = deliverable.atRiskFlag || false;
          const plannedFinishDate = deliverable.plannedFinishDate ? new Date(deliverable.plannedFinishDate) : null;
          const plannedStartDate = deliverable.plannedStartDate ? new Date(deliverable.plannedStartDate) : null;
          const completedFlag = deliverable.completedFlag || false;

          // Calculate status
          let status = 'On Track';

          if (completedFlag) {
            status = 'Completed';
          } else if (atRiskFlag) {
            status = 'At Risk';
          } else if (plannedFinishDate && plannedFinishDate < new Date() && !completedFlag) {
            // Calculate days late
            const daysLate = Math.floor((new Date().getTime() - plannedFinishDate.getTime()) / (1000 * 3600 * 24));
            status = `${daysLate} days late`;
          } else if (plannedStartDate && plannedStartDate > new Date() && !completedFlag) {
            status = 'Not Started';
          }

          return {
            deliverableName,
            isKeyMilestone,
            discipline,
            plannedFinishDate,
            phaseName,
            status,
          };
        });

        // Calculate % completed deliverable compares to all deliverables in project, all to progress field below.
        const totalDeliverables = processedDeliverables.length;
        const completedDeliverables = processedDeliverables.filter(d => d.status === 'Completed').length;
        const progressPercentage = totalDeliverables > 0 ? Math.round((completedDeliverables / totalDeliverables) * 100) : 0;

        return {
          ...project,
          deliverables: processedDeliverables,
          progress: progressPercentage,
        };
      });

      console.log('Enriched project list', deliverablesEnrichedProjectList);

      // Create a Map instead of a Set for better performance
      const allDeliverabes = new Map<string, DeliverableKey>();

      // Create a unique key function for map operations
      const getUniqueKey = (name: string, discipline: string) => `${name}|${discipline}`;

      // Collect all unique deliverable names
      deliverablesEnrichedProjectList.forEach(project => {
        if (project.deliverables && Array.isArray(project.deliverables)) {
          project.deliverables.forEach(del => {
            if (del.deliverableName) {
              const discipline = del.discipline || 'N/A';
              const uniqueKey = getUniqueKey(del.deliverableName, discipline);

              if (!allDeliverabes.has(uniqueKey)) {
                allDeliverabes.set(uniqueKey, {
                  id: uniqueKey,
                  name: del.deliverableName,
                  isKeyMilestone: del.isKeyMilestone,
                  phaseName: del.phaseName,
                  discipline,
                });
              }
            }
          });
        }
      });

      console.log('Deliverables', allDeliverabes);

      // Group deliverables by discipline
      const deliverablesByDiscipline: { [discipline: string]: DeliverableKey[] } = {};
      allDeliverabes.forEach(key => {
        if (!deliverablesByDiscipline[key.discipline]) {
          deliverablesByDiscipline[key.discipline] = [];
        }
        deliverablesByDiscipline[key.discipline].push(key);
      });

      // Store the deliverables in state
      setAllDeliverables(allDeliverabes);

      const updatedDetailsColumnsState = { ...columnsState };
      Object.entries(deliverablesByDiscipline).forEach(([discipline, keys]) => {
        keys.forEach(key => {
          if (updatedDetailsColumnsState[getUniqueKey(key.name, discipline)] === undefined) {
            // Set default value visible if no state found.
            updatedDetailsColumnsState[getUniqueKey(key.name, discipline)] = true;
          }
        });
      });

      // Clone columns array
      const deliverablesColumnIndex = columns.findIndex(col => col.key === 'deliverables');

      let allAvailableColumns = columns;

      if (deliverablesColumnIndex !== -1) {
        // Create a copy of columns without the original deliverables column
        const newColumns = [...columns.slice(0, deliverablesColumnIndex), ...columns.slice(deliverablesColumnIndex + 1)];

        // Create disciplined-based columns
        const disciplineColumns: any[] = [];

        // For each discipline, create a group of columns
        Object.entries(deliverablesByDiscipline).forEach(([discipline, keys]) => {
          const deliverableColumnsInDiscipline = keys.map(key => {
            const columnKey = `${getUniqueKey(key.name, discipline)}:date`;
            const statusKey = `${getUniqueKey(key.name, discipline)}:status`;

            return {
              title: `${key.name}`,
              key: getUniqueKey(key.name, discipline),
              width: 250,
              children: [
                {
                  title: `Date`,
                  dataIndex: columnKey,
                  key: columnKey,
                  width: 120,
                  render: (value: Date, record: any) => {
                    const bgColor = getStatusBackgroundColor(record[statusKey]);
                    return {
                      props: {
                        style: { backgroundColor: bgColor },
                      },
                      children: value?.toLocaleDateString() || <EmptyCellTable />,
                    };
                  },
                  defaultSortOrder: 'ascend' as SortOrder,
                  sorter: (a: any, b: any) => {
                    const dateA = a[columnKey] ? new Date(a[columnKey]).getTime() : 0;
                    const dateB = b[columnKey] ? new Date(b[columnKey]).getTime() : 0;
                    return dateA - dateB;
                  },
                },
                {
                  title: `Status`,
                  dataIndex: statusKey,
                  key: statusKey,
                  width: 120,
                  render: (value: string) => {
                    const bgColor = getStatusBackgroundColor(value);
                    return {
                      props: {
                        style: { backgroundColor: bgColor },
                      },
                      children: value || <EmptyCellTable />,
                    };
                  },
                  defaultSortOrder: 'ascend' as SortOrder,
                  sorter: (a: any, b: any) => {
                    // Custom priority for status values
                    const statusPriority: { [key: string]: number } = {
                      Completed: 1,
                      'On Track': 2,
                      'Not Started': 3,
                      'At Risk': 4,
                      // Any "days late" will have higher numbers based on days
                    };

                    const statusA = a[statusKey] || '';
                    const statusB = b[statusKey] || '';

                    // Handle "days late" status
                    if (statusA.includes('days late') && statusB.includes('days late')) {
                      const daysLateA = parseInt(statusA.split(' ')[0], 10) || 0;
                      const daysLateB = parseInt(statusB.split(' ')[0], 10) || 0;
                      return daysLateA - daysLateB;
                    }

                    if (statusA.includes('days late')) {
                      return 1; // "days late" comes after other statuses
                    }

                    if (statusB.includes('days late')) {
                      return -1; // Other statuses come before "days late"
                    }

                    // Sort by predetermined priority
                    return (statusPriority[statusA] || 999) - (statusPriority[statusB] || 999);
                  },
                },
              ],
            };
          });

          // Create a column group for this discipline
          disciplineColumns.push({
            title: discipline,
            key: discipline,
            children: deliverableColumnsInDiscipline,
          });
        });

        // Insert the new discipline-grouped columns
        allAvailableColumns = [
          ...newColumns.slice(0, deliverablesColumnIndex),
          ...disciplineColumns,
          ...newColumns.slice(deliverablesColumnIndex),
        ];
      }

      // Process all projects with the new column structure in one go
      const projectListWithStreamlinedDeliverables = deliverablesEnrichedProjectList.map(project => {
        const newStreamlines: Record<string, any> = {};

        // For each discipline and its deliverables
        Object.entries(deliverablesByDiscipline).forEach(([discipline, keys]) => {
          keys.forEach(key => {
            const columnKey = `${getUniqueKey(key.name, discipline)}:date`;
            const statusKey = `${getUniqueKey(key.name, discipline)}:status`;

            if (project.deliverables && Array.isArray(project.deliverables)) {
              const deliverable = project.deliverables.find(
                d => d.deliverableName === key.name && (d.discipline || 'N/A') === key.discipline
              );

              newStreamlines[columnKey] = deliverable ? deliverable.plannedFinishDate : null;
              newStreamlines[statusKey] = deliverable ? deliverable.status : '';
            } else {
              newStreamlines[columnKey] = null;
              newStreamlines[statusKey] = '';
            }
          });
        });

        return { ...project, ...newStreamlines };
      });

      setEnrichedProjectList(projectListWithStreamlinedDeliverables);

      // Check if column or any of its children should be visible
      const isColumnVisible = (column: any, stateMap: { [key: string]: boolean }): boolean => {
        // Check if the column itself has a key in the state map
        if (column.key) {
          // Extract base key by splitting at ":" and taking the first part
          const baseKey = String(column.key).split(':')[0];
          if (stateMap[baseKey] === false) {
            console.log('Hide column >> ', column.key);
            return false;
          } else if (stateMap[baseKey]) {
            console.log('Show column >> ', column.key);
            return true;
          }
        }

        // Check children if they exist
        if (column.children && Array.isArray(column.children)) {
          // If any child is visible, the parent column should also be visible
          return column.children.some((child: any) => isColumnVisible(child, stateMap));
        }

        console.log('Hide column', column.key);
        return false;
      };

      // Function to filter and keep only visible columns and their visible children
      const filterVisibleColumns = (columns: any[], stateMap: { [key: string]: boolean }) => {
        return columns.filter(column => {
          // Check if this column should be visible
          const visible = isColumnVisible(column, stateMap);

          // If column has children, filter them too
          if (visible && column.children && Array.isArray(column.children)) {
            // Only keep visible children
            column.children = filterVisibleColumns(column.children, stateMap);
          }

          return visible;
        });
      };

      // Apply the filter function to get fully processed columns
      const filteredVisibleColumns = filterVisibleColumns([...allAvailableColumns], updatedDetailsColumnsState);

      setVisibleColumns(filteredVisibleColumns);

      // Set allDeliverables to state and pass to ProjectDisplayOptions

      console.log('Project List with Deliverables', projectListWithStreamlinedDeliverables);
      console.log('All column states', updatedDetailsColumnsState);
      console.log('All available columns', allAvailableColumns);
      console.log('Filtered visible columns', filteredVisibleColumns);
      /* eslint-disable react-hooks/exhaustive-deps */
    }, [projectList, selectedTemplate, columnsState]);

    // Expose method to get CSV data
    useImperativeHandle(ref, () => ({
      showDisplayOptions: () => {
        setOpenDisplayOptions(true);
      },
      getCSVData: () => {
        // Create headers array based on visible columns
        const headers: string[] = [];

        // Add headers for visible standard columns
        visibleColumns.forEach(column => {
          // Skip the button column (view more)
          if (column.key === 'button') return;

          // Handle column groups (discipline columns with children)
          if ('children' in column && column.children) {
            // First level of children (deliverable names)
            column.children.forEach(deliverableColumn => {
              // Second level of children (date/status)
              if ('children' in deliverableColumn && deliverableColumn.children) {
                deliverableColumn.children.forEach(childColumn => {
                  if (typeof childColumn.title === 'string' && typeof deliverableColumn.title === 'string') {
                    // Create header with discipline - deliverable - attribute format
                    headers.push(`${column.title} - ${deliverableColumn.title} ${childColumn.title}`);
                  }
                });
              } else if (typeof deliverableColumn.title === 'string') {
                headers.push(`${column.title} - ${deliverableColumn.title}`);
              }
            });
          } else if (typeof column.title === 'string') {
            headers.push(column.title);
          }
        });

        // Format the data rows
        const rows = enrichedProjectList.map(project => {
          const row: (string | number | null)[] = [];

          // Add data for visible standard columns
          visibleColumns.forEach(column => {
            // Skip the button column
            if (column.key === 'button') return;

            // Handle column groups (discipline columns with children)
            if ('children' in column && column.children) {
              // First level of children (deliverable names)
              column.children.forEach(deliverableColumn => {
                // Second level of children (date/status)
                if ('children' in deliverableColumn && deliverableColumn.children) {
                  deliverableColumn.children.forEach(childColumn => {
                    // Extract the value using dataIndex as the property path
                    const dataIndex = 'dataIndex' in childColumn ? (childColumn.dataIndex as string) : undefined;

                    if (dataIndex && project[dataIndex as keyof typeof project] !== undefined) {
                      let value = project[dataIndex as keyof typeof project];

                      // Format date values
                      if (value instanceof Date) {
                        value = value.toLocaleDateString();
                      }

                      row.push(value !== null && value !== undefined ? String(value) : '');
                    } else {
                      row.push('');
                    }
                  });
                } else {
                  // Handle the case when there's only one level of nesting
                  const dataIndex = 'dataIndex' in deliverableColumn ? (deliverableColumn.dataIndex as string) : undefined;

                  if (dataIndex && project[dataIndex as keyof typeof project] !== undefined) {
                    let value = project[dataIndex as keyof typeof project];
                    row.push(value !== null && value !== undefined ? String(value) : '');
                  } else {
                    row.push('');
                  }
                }
              });
            } else {
              // Handle standard non-nested columns
              const key = column.key as string;

              // Handle specific cases with nested properties
              if (key === 'projectStatus' && project.projectStatus?.name) {
                row.push(project.projectStatus.name);
              } else if (key === 'location' && project.site?.location?.name) {
                row.push(project.site.location.name);
              } else {
                // Handle standard columns with direct dataIndex
                const dataIndex = 'dataIndex' in column ? (column.dataIndex as string) : undefined;
                if (typeof dataIndex === 'string') {
                  // Handle dot notation in dataIndex
                  if (dataIndex.includes('.')) {
                    const parts = dataIndex.split('.');
                    let value: any = project;
                    for (const part of parts) {
                      if (value === null || value === undefined) break;
                      value = value[part];
                    }
                    row.push(value !== null && value !== undefined ? String(value) : '');
                  } else {
                    const value = project[dataIndex as keyof typeof project];
                    row.push(value !== null && value !== undefined ? String(value) : '');
                  }
                } else {
                  // Fallback to using key if dataIndex is not a string
                  const value = project[key as keyof typeof project];
                  row.push(value !== null && value !== undefined ? String(value) : '');
                }
              }
            }
          });

          return row;
        });

        // Return data with headers as first row
        return [headers, ...rows];
      },
    }));

    const handleTableChange: TableProps<IProject>['onChange'] = (pagination, filter, sorter) => {
      const _sorter = sorter as SorterResult<IProject>;
      setSortingKey(String(_sorter?.field));
      setSortingOrder(_sorter?.order === 'descend' ? 'desc' : _sorter?.order === 'ascend' ? 'asc' : sortingOrder);
      setPageNumber(pagination.current !== undefined ? pagination.current - 1 : 0);
    };

    return (
      <>
        <Table
          style={{ border: `1px solid ${colorBorderSecondary}` }}
          size="small"
          rowKey="id"
          columns={visibleColumns}
          dataSource={enrichedProjectList}
          onChange={handleTableChange}
          pagination={false}
          loading={loading}
          scroll={{
            y: heightContainer - 150,
            x: 'max-content', // Enable horizontal scrolling
          }}
        />
        <ProjectDisplayOptions
          columnsState={columnsState}
          setItemsPerPage={setItemsPerPage}
          setColumnsState={data => {
            console.log('Column state updated', data);
            setColumnsState(data);
          }}
          openDisplayOptions={openDisplayOptions}
          onClose={onClose}
          deliverables={allDeliverables}
        />
      </>
    );
  }
);
