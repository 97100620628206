import { Col, DatePicker, Drawer, Form, Button, Row, theme, Table, Divider, Skeleton, FormInstance, Switch, Space, Tooltip, Alert } from 'antd'
import { IMPACT, IProjectDeliverable, ProjectDeliverableTimeLine } from '@models/project-deliverable';
import { removeTimeZone, DATE_FORMAT_DEFAULT, useDateFormatter } from '@shared/util/date-utils';
import { EmptyLabel } from '@components/Utils/EmptyLabel';
import { useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import type { Dayjs } from 'dayjs';
import { ArrowLeftOutlined, ArrowRightOutlined, InfoCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '@store/store';
import { evaluateTimelineDeliverable, reset } from '@store/slices/project-deliverable-timeline';
import { useEffect, useLayoutEffect } from 'react';
import { blue, gold, green, orange } from '@ant-design/colors';
import { useRef } from 'react';
import { getProjectDeliverableDetailById, getProjectDeliverablesData, updateProjectDeliverable } from '@store/slices/project-deliverables';
import { asyncLaunchNotification } from '@store/slices/notification';
import { getProjectOverviewData } from '@store/slices/project-overview';
import { t } from 'i18next';
import { EditTimeLineControls } from './EditTimeLineControls';
import { NEUTRAL_2_COLOR } from '@providers/ThemeProvider';
import { settingsService } from '@services/settings.service';
import { projectDeliverableWeekendOverrideKey } from '@models/setting.model';

interface EditTimelineProps {
  openEdition: boolean;
  setOpenEdition: (state: boolean) => void;
  deliverable: IProjectDeliverable;
}

const PLANED_START_DATE_FIELD = 'plannedStartDate';
const PLANED_FINISH_DATE_FIELD = 'plannedFinishDate';
const DAY_TO_INCLUDE = 1;

export interface IFormValues {
  [PLANED_START_DATE_FIELD]?: Dayjs | null;
  [PLANED_FINISH_DATE_FIELD]?: Dayjs | null;
}
export interface IRequestDatesValues {
  [PLANED_START_DATE_FIELD]?: string;
  [PLANED_FINISH_DATE_FIELD]?: string;
}

const thHeaderDates = (fistRow: string, secondRow: string, classNames?: string) => (
  <div className={`flex flex-col justify-end items-end ${classNames}`} style={{ fontSize: 12 }}>
    <span>{fistRow}</span>
    <span>{secondRow}</span>
  </div>
);

const NewAlign = ({ value, record }) => {
  const {
    token: { colorSuccess, colorError },
  } = theme.useToken();

  const { dfFormatDatePipe } = useDateFormatter();

  const getColor = (impact: string) => {
    if (!impact) return;
    return impact === IMPACT.LEFT ? colorSuccess : impact === IMPACT.EQUAL ? blue[6] : colorError;
  };

  const getIcon = (impact: string) => {
    if (!impact) return;
    const colorIcon = getColor(impact);
    return impact === IMPACT.LEFT ? (
      <ArrowLeftOutlined color={colorIcon} />
    ) : impact === IMPACT.EQUAL ? (
      <></>
    ) : (
      <ArrowRightOutlined color={colorIcon} />
    );
  };

  const getStyleColor = (impact: string) => {
    if (!impact) return;
    return { color: getColor(impact) };
  };
  return (
    <span className="pr-15" style={getStyleColor(record?.plannedFinishDate?.diff)}>
      <>
        <span className="mr-10">{getIcon(record?.plannedFinishDate?.diff)}</span>
        {dfFormatDatePipe(record?.plannedFinishDate?.newDate)}
      </>
    </span>
  );
};

const NewPlans = ({ value, record }) => {
  const {
    token: { colorSuccess, colorError },
  } = theme.useToken();

  const { dfFormatDatePipe } = useDateFormatter();

  const getColor = (impact: string) => {
    if (!impact) return;
    return impact === IMPACT.LEFT ? colorSuccess : impact === IMPACT.EQUAL ? blue[6] : colorError;
  };

  const getIcon = (impact: string) => {
    if (!impact) return;
    const colorIcon = getColor(impact);
    return impact === IMPACT.LEFT ? (
      <ArrowLeftOutlined color={colorIcon} />
    ) : impact === IMPACT.EQUAL ? (
      <></>
    ) : (
      <ArrowRightOutlined color={colorIcon} />
    );
  };

  const getStyleColor = (impact: string) => {
    if (!impact) return;
    return { color: getColor(impact) };
  };
  return (
    <span style={getStyleColor(record?.plannedStartDate?.diff)}>
      <>
        <span className="mr-10">{getIcon(record?.plannedStartDate?.diff)} </span>
        {dfFormatDatePipe(record?.plannedStartDate?.newDate)}
      </>
    </span>
  );
};

export const EditTimeLine: React.FC<EditTimelineProps> = ({ openEdition, setOpenEdition, deliverable }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [lastFieldDateEditted, setLastFieldDateEditted] = useState<keyof IFormValues | null>(null);

  const {
    token: {
      colorPrimaryBorder,
      colorWarningBorder,
      colorWarningBg,
      colorPrimaryActive,
      colorBgContainer,
      padding,
      colorTextTertiary,
      colorTextQuaternary,
    },
  } = theme.useToken();
  const [hasPreviousEvaluation, setHasPreviousEvaluation] = useState(false);
  const [isRequestOfEvaluationSent, setIsRequestOfEvaluationSent] = useState(false);
  const [isNotApplicable, setIsNotApplicable] = useState<boolean>(Boolean(deliverable.notApplicableFlag));
  const [toggleNotApplicable, setToggleNotApplicable] = useState(false);

  const [canOverrideWeekend, setCanOverrideWeekend] = useState(false);
  useEffect(() => {
    settingsService.getOneSettingByKey(projectDeliverableWeekendOverrideKey).then(settingResponse => {
      if (settingResponse.data.value !== undefined && settingResponse.data.value !== null && settingResponse.data.value.trim().length > 0) {
        setCanOverrideWeekend(Boolean(settingResponse.data.value === 'true'));
      }
    });
  }, []);

  const { projectId } = useParams<'projectId'>();

  const [form] = Form.useForm();

  const deliverableDuration = deliverable?.duration || 1;
  const [durationSuggested, setDurationSuggested] = useState(deliverableDuration);
  const durationSuggestedIncludingDateSelected = () => (durationSuggested ? durationSuggested - 1 : 0);

  const [initialFormValues, setInitialFormValues] = useState<IFormValues>({});

  useLayoutEffect(() => {
    /**
     * Set Inital Dates PlannedStartDate and PlannedFinishDate
     * */
    const { plannedStartDate, plannedFinishDate } = deliverable;

    setDurationSuggested(deliverableDuration);

    form.resetFields();

    setInitialFormValues({
      ...(plannedStartDate && { plannedStartDate: dayjs(plannedStartDate) }),
      ...(plannedFinishDate && { plannedFinishDate: dayjs(plannedFinishDate) }),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openEdition, deliverable]);

  const onClose = () => {
    setOpenEdition && setOpenEdition(false);
    setToggleNotApplicable(false);
    setIsNotApplicable(Boolean(deliverable.notApplicableFlag));
    setTimeout(() => {
      resetProperties();
    }, 500);
  };

  const onSave = () => {
    if (!deliverable?.id) {
      return;
    }
    dispatch(
      updateProjectDeliverable({
        projectId: String(projectId),
        deliverableId: String(deliverable?.id),
        bodyRequest: {
          id: deliverable.id,
          notApplicableFlag: isNotApplicable,
        },
        onSuccess: onSucessUpdateProjectDeliverable,
      })
    );
  };

  const onSucessUpdateProjectDeliverable = () => {
    dispatch(getProjectOverviewData(String(projectId)));
    dispatch(getProjectDeliverablesData(String(projectId)));
    dispatch(getProjectDeliverableDetailById({ projectDeliverableId: String(deliverable.id) }));
    dispatch(
      asyncLaunchNotification({
        type: 'success',
        config: {
          message: `Project Deliverable`,
          description: `Project deliverable updated successfully`,
        },
      })
    );
  };

  const resetProperties = () => {
    dispatch(reset());
    setIsRequestOfEvaluationSent(false);
    setHasPreviousEvaluation(false);
    setLastFieldDateEditted(null);
    setDurationSuggested(0);
  };

  const ContainerEditionTimeLine = ({ form }: { form: FormInstance<any> }) => {
    const footerRangerPicker = () => (
      <div className="flex flex-col justify-end items-start" style={{ paddingRight: padding, paddingLeft: padding }}>
        <span>
          <span className="font-semibold mr-5">Duration:</span>
          <span>{durationSuggested} days</span>
        </span>
      </div>
    );

    /**
     * It's Executed when Form change their values
     * @Dependency deliverableDuration, form
     * */
    const onValuesFormChange = (
      changedFields: { plannedStartDate: Dayjs | null; plannedFinishDate: Dayjs | null },
      values: IFormValues
    ): void => {
      if (!deliverableDuration || deliverableDuration < 1 || !form) return;

      const { plannedStartDate, plannedFinishDate } = changedFields;
      if (plannedStartDate) {
        form.setFieldValue(PLANED_FINISH_DATE_FIELD, addDaysSkippingWeekends(plannedStartDate, durationSuggestedIncludingDateSelected()));
        setLastFieldDateEditted(PLANED_START_DATE_FIELD);
      }
      if (plannedFinishDate) {
        if (values[PLANED_FINISH_DATE_FIELD] && values[PLANED_START_DATE_FIELD]) {
          const newDuration =
            diffBetweenDaysSkippingWeekends(values[PLANED_START_DATE_FIELD], values[PLANED_FINISH_DATE_FIELD]) + DAY_TO_INCLUDE;
          setDurationSuggested(newDuration);
        }

        setLastFieldDateEditted(PLANED_FINISH_DATE_FIELD);
      }
    };

    const isWeekend = (current: Dayjs) => {
      return !!(current.day() === 0 || current.day() === 6);
    };

    const addDaysSkippingWeekends = (current: Dayjs, days: number) => {
      let result: Dayjs = current;
      let addedDays = 0;
      while (addedDays < days) {
        result = dayjs(result).add(1, 'days');
        if (!isWeekend(result)) {
          ++addedDays;
        }
      }
      return result;
    };

    const diffBetweenDaysSkippingWeekends = (dateFrom: Dayjs, dateTo: Dayjs) => {
      let result = 0;
      while (dateTo.isAfter(dateFrom)) {
        if (!isWeekend(dateTo)) {
          ++result;
        }
        dateTo = dayjs(dateTo).subtract(1, 'days');
      }
      return result;
    };

    /**
     * Function used to disable some dates in DatePicker when condition it's true, example: PlannedStartDate can't be less than today.
     * */
    const disabledPlannedStartDate = (current: Dayjs) => {
      const disabledDates = canOverrideWeekend ? false : isWeekend(current);
      return !!disabledDates;
    };

    /**
     * Function used to disable some dates in DatePicker when condition it's true,
     * @Dependency: deliverableDuration
     * */
    const disabledPlannedEndDate = (current: Dayjs) => {
      const lessThanToday =
        current &&
        current <
          dayjs(deliverable.actualStartDate ? deliverable.actualStartDate : form.getFieldValue(PLANED_START_DATE_FIELD)).startOf('day');
      const disableWeekend = canOverrideWeekend ? false : isWeekend(current);
      const disabledDates = lessThanToday || disableWeekend;
      return !!disabledDates;
    };

    const { data: Timeline, loading, applySuccess } = useAppSelector(state => state.ProjectDeliverableTimeLine);

    const handleSubmit = (fields: IFormValues) => {
      setIsRequestOfEvaluationSent(true);
      setHasPreviousEvaluation(!hasPreviousEvaluation);

      if (Number(projectId) && Number(deliverable.id) && lastFieldDateEditted) {
        const dates = {} as IRequestDatesValues;

        if (durationSuggested !== deliverableDuration && fields[PLANED_START_DATE_FIELD] && fields[PLANED_START_DATE_FIELD]) {
          dates[PLANED_START_DATE_FIELD] = removeTimeZone(fields[PLANED_START_DATE_FIELD]);
          dates[PLANED_FINISH_DATE_FIELD] = removeTimeZone(fields[PLANED_FINISH_DATE_FIELD]);
        } else {
          dates[lastFieldDateEditted] = removeTimeZone(fields[lastFieldDateEditted]);
        }

        const evaluateParams = {
          projectId: String(projectId),
          projectDeliverableId: String(deliverable.id),
          requestBody: {
            projectDeliverableId: String(deliverable.id),
            ...dates,
          },
        };
        dispatch(evaluateTimelineDeliverable(evaluateParams));
      }
    };

    const handleErrorForm = (errorInfo: any) => {
      // on Error
    };

    const onChangeNotApplicable = (checked: boolean) => {
      setIsNotApplicable(checked);
      setToggleNotApplicable(!toggleNotApplicable);
    };

    useEffect(() => {
      if (applySuccess) {
        onClose();
      }
    }, [applySuccess]);

    const reference = useRef<HTMLDivElement>(null);
    const { dfFormatDatePipe } = useDateFormatter();

    const columns: ColumnsType<ProjectDeliverableTimeLine> = [
      {
        title: 'Deliverables',
        render: (value: any, record: ProjectDeliverableTimeLine) => (
          <span>{record?.projectDeliverableName || record?.projectDeliverableId || <EmptyLabel />}</span>
        ),
        width: '20%',
      },
      {
        title: (
          <div
            className="flex items-center w-full pl-10 justify-center"
            style={{ borderTopLeftRadius: 4, backgroundColor: '#f8f9f6', minHeight: '28px', borderBottom: '1px solid #d8dad1' }}
          >
            Planned Start Date
          </div>
        ),
        children: [
          {
            title: thHeaderDates('Current', ''),
            dataIndex: 'plannedStartDate',
            key: 'plannedStartDate',
            align: 'right',
            render: (text, record) => <span>{dfFormatDatePipe(record?.plannedStartDate?.oldDate) || <EmptyLabel />} </span>,
          },
          {
            title: thHeaderDates('New', ''),
            dataIndex: 'plannedFinishDate',
            key: 'plannedFinishDate',
            align: 'right',
            render: (text, record) => <span>{dfFormatDatePipe(record?.plannedFinishDate?.oldDate) || <EmptyLabel />}</span>,
          },
        ],
      },
      {
        title: (
          <div
            className="flex items-center w-full pl-10 justify-center"
            style={{
              borderTopRightRadius: 4,
              backgroundColor: '#f8f9f6',
              minHeight: '28px',
              borderBottom: '1px solid #d8dad1',
              borderLeft: 'none',
            }}
          >
            Planned End Date
          </div>
        ),
        children: [
          {
            title: thHeaderDates('Current', ''),
            dataIndex: 'newPlannedStartDate',
            key: 'newPlannedStartDate',
            align: 'right',
            render: (text, record) => <NewPlans value={text} record={record} />,
          },
          {
            title: thHeaderDates('New', ''),
            dataIndex: 'newPlannedFinishDate',
            key: 'newPlannedFinishDate',
            align: 'right',
            render: (text, record) => <NewAlign value={text} record={record} />,
          },
        ],
      },
    ];

    const Evaluation = () => (
      <div className="generic-transition">
        <Divider className="mt-40 mb-30" />

        <Row className="pb-30">
          <Col span={24}>
            <span className="font-bold" style={{ color: colorTextTertiary }}>
              Impact from changes:
            </span>
          </Col>
        </Row>

        <Skeleton loading={loading} paragraph={{ rows: 14 }} active={true}>
          <Row>
            <Col span={24}>
              <div ref={reference}>
                <Table
                  className="bordered-top-right-table"
                  rowKey="projectDeliverableId"
                  columns={columns}
                  dataSource={Timeline.evaluation.timeline}
                  size="small"
                  style={{ borderStartStartRadius: 20 }}
                  pagination={false}
                />
              </div>
            </Col>
          </Row>
          <EditTimeLineControls onClose={onClose} deliverable={deliverable} />
        </Skeleton>
      </div>
    );

    return (
      <div
        style={{
          paddingBottom: '130px',
          paddingTop: (deliverable.notApplicableFlag ? isNotApplicable : deliverable.notApplicableFlag) ? '20px' : 0,
        }}
      >
        <div>
          <div className="pl-10 pt-15">
            {(deliverable.notApplicableFlag ? isNotApplicable : deliverable.notApplicableFlag) ? (
              <div
                style={{
                  backgroundColor: colorWarningBg,
                  borderBottom: `1px solid ${colorWarningBorder}`,
                  alignItems: 'center',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                  width: '100%',
                  position: 'absolute',
                  top: 57,
                  marginLeft: '-32px',
                  marginRight: '-22px',
                }}
              >
                <Alert
                  message={`This deliverable is set as "Not Applicable," so its dates will not affect the rest of the dependencies.`}
                  banner
                />
              </div>
            ) : null}
            <Form
              layout="vertical"
              form={form}
              initialValues={initialFormValues}
              onFinish={handleSubmit}
              onFinishFailed={handleErrorForm}
              onValuesChange={onValuesFormChange}
            >
              <Row gutter={16} className="pb-5">
                <Col span={12}>
                  <span>
                    <span className="font-bold mr-5">Planned Start Date:</span>
                    {dfFormatDatePipe(deliverable?.plannedStartDate) || <EmptyLabel />}
                  </span>
                </Col>
                <Col span={12}>
                  <span>
                    <span className="font-bold mr-5">Planned Finish Date:</span>
                    {dfFormatDatePipe(deliverable?.plannedFinishDate) || <EmptyLabel />}
                  </span>
                </Col>
              </Row>

              <Row gutter={16} className="pb-5">
                <Col span={12}>
                  <span>
                    <span className="font-bold mr-5">Current Duration:</span>
                    {(deliverableDuration && `${deliverableDuration} days`) || <EmptyLabel />}
                  </span>
                </Col>
                <Col span={12}></Col>
              </Row>
              <Divider className="mt-10 mb-20" />

              <Row gutter={16} className="pb-5">
                <Col span={12}>
                  <span>
                    <span className="font-bold mr-5">Set as Not Applicable</span>
                    <Tooltip placement="top" title={Boolean(deliverable.actualStartDate) ? 'The deliverable has been started.' : ''}>
                      <Switch
                        checked={Boolean(isNotApplicable)}
                        onChange={onChangeNotApplicable}
                        disabled={Boolean(deliverable.actualStartDate)}
                      />
                    </Tooltip>
                  </span>
                </Col>
                <Col span={12}></Col>
              </Row>

              <Divider className="mt-10 mb-20" />
              <div className="pl-10" style={{ backgroundColor: 'white' }}>
                <Row gutter={16} className="pl-10 pt-15 border-color-fill container-shadow-bottom rounded-3">
                  <Col span={10}>
                    <Form.Item
                      name={PLANED_START_DATE_FIELD}
                      className="mb-10"
                      label={
                        <span className="font-bold" style={{ color: isNotApplicable || toggleNotApplicable ? colorTextQuaternary : '' }}>
                          New Planned Start Date
                        </span>
                      }
                    >
                      <DatePicker
                        format={DATE_FORMAT_DEFAULT}
                        className="w-full border-color-fill rounded-3"
                        allowClear={false}
                        placeholder="Planned Start Date"
                        disabledDate={disabledPlannedStartDate}
                        renderExtraFooter={footerRangerPicker}
                        disabled={Boolean(deliverable.actualStartDate) || Boolean(isNotApplicable) || Boolean(toggleNotApplicable)}
                        cellRender={rawCurrent => {
                          const current = dayjs(rawCurrent);

                          const duration = durationSuggested;
                          const dateSelected = form.getFieldValue(PLANED_START_DATE_FIELD);
                          const style: React.CSSProperties = {};

                          if (duration && dateSelected) {
                            if (
                              current > dayjs(dateSelected) &&
                              current < addDaysSkippingWeekends(dateSelected, duration) &&
                              !isWeekend(current)
                            ) {
                              style.border = `1px solid ${colorPrimaryBorder}`;
                              style.backgroundColor = `${colorPrimaryBorder}`;
                              style.color = colorBgContainer;
                              style.transform = 'scale(0.85)';
                            }
                          }

                          return (
                            <div className="ant-picker-cell-inner" style={style}>
                              {current.date()}
                            </div>
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      className="mb-10"
                      name={PLANED_FINISH_DATE_FIELD}
                      label={
                        <span className="font-bold" style={{ color: isNotApplicable || toggleNotApplicable ? colorTextQuaternary : '' }}>
                          New Planned Finish Date
                        </span>
                      }
                    >
                      <DatePicker
                        format={DATE_FORMAT_DEFAULT}
                        className="w-full border-color-fill rounded-3"
                        allowClear={false}
                        disabled={Boolean(isNotApplicable) || Boolean(toggleNotApplicable)}
                        placeholder="Planned End Date"
                        disabledDate={disabledPlannedEndDate}
                        renderExtraFooter={footerRangerPicker}
                        cellRender={rawCurrent => {
                          const current = dayjs(rawCurrent);

                          const duration = durationSuggested;
                          const startDate = form.getFieldValue(PLANED_START_DATE_FIELD);
                          const dateSelected = form.getFieldValue(PLANED_FINISH_DATE_FIELD);
                          const style: React.CSSProperties = {};

                          if (current.isSame(startDate)) {
                            style.border = `1px solid ${colorPrimaryActive}`;
                            style.backgroundColor = `${colorPrimaryActive}`;
                            style.color = colorBgContainer;
                          }

                          if (duration && dateSelected) {
                            if (current > startDate && current < dayjs(dateSelected) && !isWeekend(current)) {
                              style.border = `1px solid ${colorPrimaryBorder}`;
                              style.backgroundColor = `${colorPrimaryBorder}`;
                              style.color = colorBgContainer;
                              style.transform = 'scale(0.85)';
                            }
                          }

                          return (
                            <div className="ant-picker-cell-inner" style={style}>
                              {current.date()}
                            </div>
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item className="mb-10" name={''} label={<></>}>
                      <Button
                        type="default"
                        className="rounded-3"
                        style={{ backgroundColor: NEUTRAL_2_COLOR }}
                        disabled={lastFieldDateEditted === null}
                        onClick={() => form.submit()}
                      >
                        <ArrowRightOutlined className="mr-5" /> Forecast
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>

          {isNotApplicable && toggleNotApplicable && (
            <div
              className="mt-20 pt-20 pb-20 pl-20 pr-20"
              style={{ border: `1px solid ${orange[2]}`, color: gold[9], backgroundColor: gold[0], borderRadius: 4 }}
            >
              <Row className="pb-4 text-center">
                <Col span={24} className="font-bold">
                  <InfoCircleFilled className="" style={{ color: gold[4] }} />
                </Col>
              </Row>
              <Row className="pb-4 text-center pt-5">
                <Col span={24} className="pt-5">
                  Once saved, this deliverable will be set
                </Col>
                <Col span={24} className="pt-5">
                  as "Not Applicable", meaning it will not
                </Col>
                <Col span={24} className="pt-5">
                  be impacted by dependencies.
                </Col>
              </Row>
              <Row className="pb-4 text-center pt-25">
                <Col span={24}>Click cancel to avoid this change.</Col>
              </Row>
            </div>
          )}

          {!isNotApplicable && toggleNotApplicable && (
            <div
              className="mt-20 pt-20 pb-20 pl-20 pr-20"
              style={{ border: `1px solid ${green[2]}`, color: green[7], backgroundColor: green[0], borderRadius: 4 }}
            >
              <Row className="pb-4 text-center">
                <Col span={24} className="font-bold">
                  <InfoCircleFilled className="" style={{ color: green[2] }} />
                </Col>
              </Row>
              <Row className="pb-4 text-center pt-5">
                <Col span={24} className="pt-5">
                  Once saved this deliverable will now
                </Col>
                <Col span={24} className="pt-5">
                  be included in schedule calculations.
                </Col>
              </Row>
              <Row className="pb-4 text-center pt-25">
                <Col span={24}>To avoid this change click cancel.</Col>
              </Row>
            </div>
          )}
        </div>

        {isRequestOfEvaluationSent && <Evaluation />}
      </div>
    );
  };

  return (
    <Drawer
      title={`${t('generic.manageSchedule')}`}
      width={850}
      placement="right"
      onClose={onClose}
      open={openEdition}
      destroyOnClose={true}
      forceRender
      extra={
        toggleNotApplicable && (
          <Space>
            <Button type="primary" ghost onClick={onClose}>
              Cancel
            </Button>
            <Button type="primary" onClick={onSave}>
              Save
            </Button>
          </Space>
        )
      }
    >
      <ContainerEditionTimeLine form={form} />
    </Drawer>
  );
};