import dayjs, { Dayjs } from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import { usePreferencesProvider } from "@providers/PreferencesProvider";

// Enable the relativeTime plugin
dayjs.extend(relativeTime);

// Set the locale to English (optional)
dayjs.locale('en');

export const DATE_FORMAT_DEFAULT = "MM/DD/YYYY";
export const DATETIME_FORMAT_DEFAULT = 'MM/DD/YYYY HH:mm:ss';
export const DATETIME_FORMAT_AM_PM = 'MM/DD/YYYY hh:mm A';
export const DATETIME_FULL_FORMAT_DEFAULT = "dddd, MMMM D, YYYY, h:mm A";
export const DATETIME_FULL_FORMAT_WITHTOUT_DAY_NAME = "MMMM D, YYYY, h:mm A";
export const DATE_FORMAT_WITHTOUT_TIMEZONE = "YYYY-MM-DD";
export const DATE_FORMAT_FULL_NAME_MONTH = "MMMM DD, YYYY";
export const DATE_FORMAT_FULL_NAME_MONTH_2 = "MMMM D, YYYY";

export const useDateFormatter = () => {
    const { userSettings } = usePreferencesProvider();

    const dfFormatDatePipe = (rawDate?: string | null) => {
        const format = userSettings?.settings?.dateFormat === 'EU' ? DATE_FORMAT_WITHTOUT_TIMEZONE : DATE_FORMAT_DEFAULT;;
        return formatDatePipe(rawDate, format);
    }

    return { dfFormatDatePipe };
}

export const formatDatePipe = (rawDate?: string | null, format?: string): string => {
    if (!rawDate) return '';
    return dayjs(rawDate)?.format(format || DATE_FORMAT_DEFAULT);
}

export const formatDatetimePipe = (rawDate?: string | null): string => {
    if (!rawDate) return '';
    return dayjs?.(rawDate)?.format(DATETIME_FORMAT_DEFAULT);
}

export const formatDatetimeWithAmPm = (rawDate?: string | null): string => {
    if (!rawDate) return '';
    return dayjs?.(rawDate)?.format(DATETIME_FORMAT_AM_PM)
}

export const formatDateTargetOpening = (rawDate?: string | null): string => {
    if (!rawDate) return '';
    return dayjs?.(rawDate)?.format("MMMM YYYY");
}

export const formatFullDatetime = (rawDate?: string | null): string => {
    if (!rawDate) return '';
    return dayjs?.(rawDate)?.format(DATETIME_FULL_FORMAT_DEFAULT)
}

export const formatFullDatetimeWhitoutDayName = (rawDate?: string | null): string => {
    if (!rawDate) return '';
    return dayjs?.(rawDate)?.format(DATETIME_FULL_FORMAT_WITHTOUT_DAY_NAME)
}

export const formatFullNameMonth = (rawDate?: string | null): string => {
    if (!rawDate) return '';
    return dayjs?.(rawDate)?.format(DATE_FORMAT_FULL_NAME_MONTH_2)
}

export const removeTimeZone = (day?: Dayjs | null, format?: string): string => {
    if (!day) return '';
    return day?.format?.(format || DATE_FORMAT_WITHTOUT_TIMEZONE);
}

export const getTodayDate = () => {
    return dayjs?.()?.format(DATE_FORMAT_WITHTOUT_TIMEZONE) 
}

export const fromNow = (date: string) => {
    return dayjs?.(date)?.fromNow()
}

export const toNow = (date: string) => {
    return dayjs?.(date)?.toNow()
}

export const getDate = (date?: string) => {
    return dayjs?.(date);
}

export const getISODate = (date?: string) => {
    return getDate?.(date)?.toISOString();
}


export const generateUniqueStringWithTimestamp = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
    const second = String(now.getSeconds()).padStart(2, '0');
    const miliseconds = String(now.getMilliseconds());
  
    return `${year}${month}${day}${hour}${minute}${second}${miliseconds}`;
}