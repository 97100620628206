/* eslint-disable react-hooks/exhaustive-deps */
import { IProjectDeliverableUI } from "@models/project-deliverable";
import { Button, Col, Tabs, Drawer, Row, Space } from "antd";
import { useAppDispatch } from "@store/store";
import { useTranslation } from "react-i18next";
import { useRef, useState } from 'react';
import { getProjectDeliverablesData, updateProjectDeliverable , removeProjectDeliverableActualDates } from "@store/slices/project-deliverables";
import { useParams } from "react-router-dom";
import { getProjectOverviewData } from "@store/slices/project-overview";
import { asyncLaunchNotification } from "@store/slices/notification";
import { DeliverableHandleDependencyModalContent, IDeliverableHandleDependencyModalContentRef } from "./DeliverableDetailsDependencyModalContent";
import { DeliverableHandleTimelineModalContent, IDeliverableHandleTimelineModalContentRef } from "./DeliverableDetailsTimelineModalContent";
import { getProjectDeliverableDetailById } from '@store/slices/project-deliverables';


export interface IDeliverableHandleDependencyModalProps {
    open: boolean;
    toogle: () => void;
    deliverable: IProjectDeliverableUI;
}

export interface IDeliverableRemoveActualDatesCheckedState {
    startDate: boolean;
    endDate: boolean;
    atRiskFlag: boolean;
}
export interface IDeliverableRemoveActualDatesDisabledState {
    startDate: boolean;
    endDate: boolean;
    atRiskFlag: boolean;
}

export const DeliverableHandleDependencyModal = (props: IDeliverableHandleDependencyModalProps) => {

    const { open, toogle, deliverable } = props;

    const [allowRemoveDates, setCanRemoveStatus] = useState<string>("NOT_LOADED")

    const [checked, setChecked] = useState<IDeliverableRemoveActualDatesCheckedState>({
        startDate: false,
        endDate: false,
        atRiskFlag: false,
    });

    const [disabledStatus, setDisabledStatus] = useState<IDeliverableRemoveActualDatesDisabledState>({
        startDate: false,
        endDate: false,
        atRiskFlag: false
    });

    const dispatch = useAppDispatch();

    const { projectId } = useParams<"projectId">();

    const { t } = useTranslation();

    const contentModalRef = useRef<IDeliverableHandleDependencyModalContentRef>()
    const timelineContentModalRef = useRef<IDeliverableHandleTimelineModalContentRef>()

    const [activeTab, setActiveTab] = useState<string>("dependency")

    const onSucessUpdateProjectDeliverable = () => {
        dispatch(getProjectOverviewData(String(projectId)));
        dispatch(getProjectDeliverableDetailById({ projectDeliverableId: String(deliverable.id) }));
        dispatch(asyncLaunchNotification({
            type: "success",
            config: {
                message: t("projectDeliverable.messages.changesSaved"),
                description: t("projectDeliverable.messages.dependencyModified")
            }
        }));
    }


    const onSucessRemoveActualDates = () => {
        dispatch(getProjectOverviewData(String(projectId)));
        dispatch(getProjectDeliverablesData(String(projectId)));
        dispatch(getProjectDeliverableDetailById({ projectDeliverableId: String(deliverable.id) }));
        dispatch(asyncLaunchNotification({
            type: "success",
            config: {
                message: `Project Deliverable`,
                description: `Project timeline updated successfully`
            }
        }))
    }

    const onTimelineOkButton = () => {
        if (!deliverable?.id) { return };
        const shouldFinishDateBeRemoved = Boolean(checked.startDate) ? Boolean(checked.startDate) : Boolean(checked.endDate)

        const updateActualDates = ()=> {
            dispatch(removeProjectDeliverableActualDates({
                projectId: String(projectId),
                deliverableId: String(deliverable?.id),
                bodyRequest: {
                    undoActualFinishDate: shouldFinishDateBeRemoved,
                    undoActualStartDate: Boolean(checked.startDate)
                },
                onSuccess: onSucessRemoveActualDates
            }));
        }

        dispatch(updateProjectDeliverable({
            projectId: String(projectId),
            deliverableId: String(deliverable?.id),
            bodyRequest: {
                id: deliverable?.id,
                atRiskFlag: checked.atRiskFlag,
            },
            onSuccess: updateActualDates
        }))


    }

    const onDependencyOkButton = () => {
        const deliverableDetailsForm = contentModalRef.current?.getValues();
        dispatch(updateProjectDeliverable({
            projectId: String(projectId),
            deliverableId: String(deliverable?.id),
            bodyRequest: {
                id: deliverable?.id,
                dependencyOffset: deliverableDetailsForm?.offset,
                dependency: {
                    id: deliverableDetailsForm?.dependency
                }
            },
            onSuccess: onSucessUpdateProjectDeliverable
        }))
    }

    const onClose = () => {
        toogle?.();
    }

    const header = (
        <>
            <Row justify="space-between" align={"middle"} className="pt-15 pb-0" >
                <Col flex="auto" className="flex items-center justify-start pl-30">
                    <h3>{t("projectDeliverable.editDetails")}</h3>
                </Col>
            </Row>
        </>
    );

    return (
        // Modal was replaced by drawer for mobile compatibility
        <Drawer
            title={header}
            width={580}
            onClose={onClose}
            open={open}
            closable={false}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
                <Space>
                    <Button type="primary" ghost onClick={onClose}>
                        Cancel
                    </Button>
                    <Button onClick={activeTab === "timeline" ? onTimelineOkButton : onDependencyOkButton} type="primary">
                        Save
                    </Button>
                </Space>
            }
        >
            <Tabs
                style={{ padding: "0 15px" }}
                type="card"
                onChange={setActiveTab}
                items={[
                    {
                        label: `Dependency`,
                        key: 'dependency',
                        children:
                            <Col span={24} flex={"auto"} style={{ minHeight: 450 }} >
                                {
                                    open && <DeliverableHandleDependencyModalContent
                                        ref={contentModalRef}
                                        deliverable={deliverable}
                                    />
                                }
                            </Col>
                    },
                    {
                        label: `${t(`projectDeliverable.timeline`)}`,
                        key: 'timeline',
                        children:
                            <Col span={24} flex={"auto"} style={{ minHeight: 450 }} >
                                {
                                    open && <DeliverableHandleTimelineModalContent
                                        ref={timelineContentModalRef}
                                        deliverable={deliverable}
                                        allowRemoveDates={allowRemoveDates}
                                        setCanRemoveStatus={setCanRemoveStatus}
                                        checked={checked}
                                        setChecked={setChecked}
                                        disabledStatus={disabledStatus}
                                        setDisabledStatus={setDisabledStatus}
                                    />
                                }
                            </Col>,
                    }
                ]}
            />
        </Drawer >
    )
}
