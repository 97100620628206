import React from 'react'
import { ICustomFieldExtendedUI } from '@models/custom-field.model';
import { CustomFieldTypeEnum } from '@models/enumerations/custom-field-type-enum.model';
import { useDateFormatter } from '@shared/util/date-utils';
import { formatCurrency } from '@shared/util/currency-utils';
import HyperlinkHighlighter from '@components/Utils/HyperlinkHighlighter';

interface ICustomFieldValueGenericProps {
    customField: ICustomFieldExtendedUI
}

export const CustomFieldGenericValue: React.FC<ICustomFieldValueGenericProps> = (props) => {

    const { customField } = props;
    const { jsonValueProccessed, fieldType  } = customField;
    const { dfFormatDatePipe } = useDateFormatter();

    switch (`${fieldType}`) {
        case CustomFieldTypeEnum.STRING:
          return <HyperlinkHighlighter text={jsonValueProccessed} /> || NAtag;

        case CustomFieldTypeEnum.SELECTOR:
        case CustomFieldTypeEnum.INTEGER:
        case CustomFieldTypeEnum.DECIMAL:
          return jsonValueProccessed || NAtag;

        case CustomFieldTypeEnum.CURRENCY:
          return (jsonValueProccessed) ? formatCurrency(jsonValueProccessed) : NAtag;

        case CustomFieldTypeEnum.BOOLEAN:
          if (jsonValueProccessed === null) return NAtag;
          if (jsonValueProccessed === undefined) return NAtag;
  
          const valueBoolean = Boolean(jsonValueProccessed) ? YesTag : NoTag;
          return valueBoolean;

        case CustomFieldTypeEnum.DATE:
          const dateFormatted = jsonValueProccessed && dfFormatDatePipe(jsonValueProccessed);
          return `${dateFormatted || NAtag}`;

        default:
          return NAtag;
    }
}

const NAtag = "n/a";
const YesTag = "Yes";
const NoTag = "No";