import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Row, theme, Button, Card, Select, Steps, Tabs, Table, Typography } from 'antd';
import moment from 'moment';

import { useAppDispatch, useAppSelector } from '@store/store';
import { TabContainer } from '@components/Utils/TabContainer';
import { asyncLaunchNotification } from '@store/slices/notification';
import { useLayoutContex } from '@providers/LayoutProvider';
import { bulkUploadService } from '../../services/bulk-upload.service';
import { getEntities as getBatches } from '@store/slices/bulk-upload-batch';

const { Step } = Steps;
const { TabPane } = Tabs;
const { Link } = Typography;

export const BulkUploaderTab = () => {
  const {
    token: { marginLG }
  } = theme.useToken();

  const { entities: batches } = useAppSelector((state) => state.BulkUploadBatches);

  const [form] = Form.useForm();
  const [animateError, setAnimateError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [csvData, setCsvData] = useState<string | null>(null);
  const [fileInfo, setFileInfo] = useState<{ size: number } | null>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [uploadBatchResult, setUploadBatchResult] = useState<any>(null);
  const [commitBatchResult, setCommitBatchResult] = useState<any>(null);

  const [showUploadWizard, setShowUploadWizard] = useState(false);
  const [issueTableData, setIssueTableData] = useState<any[]>([]);

  const [showRevertWizard, setShowRevertWizard] = useState(false);
  const [revertStep, setRevertStep] = useState(0);
  const [revertBatchId, setRevertBatchId] = useState<string | null>(null);
  const [revertResult, setRevertResult] = useState<any>(null);

  const [templateLink, setTemplateLink] = useState<string | null>(null);
  const [showFileUploadSection, setShowFileUploadSection] = useState(false);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { setHeaderLabelEntityState } = useLayoutContex();

  useEffect(() => {
    setHeaderLabelEntityState(t('admin.bulkUploader'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const loadBatches = () => {
    dispatch(
      getBatches({ sort: 'createdAt,desc', page: 0, size: 50 })
    );
  };

  useEffect(() => {
    loadBatches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setFileInfo({ size: file.size });
      const reader = new FileReader();
      reader.onload = (event) => {
        setCsvData(event.target?.result as string);
      };
      reader.readAsText(file);
    }
  };

  // Parse CSV data if available
  // const parsedRecords = useMemo(() => {
  //   if (!csvData) return { headers: [] as string[], data: [] as string[][] };
  //   const lines = csvData.trim().split('\n');
  //   const records = lines.map(line => line.split(','));
  //   const headers = records.length > 0 ? records[0] : [];
  //   const data = records.length > 1 ? records.slice(1) : [];
  //   return { headers, data };
  // }, [csvData]);

  // Compute statistics: file size and total record count (excluding header)
  // const totalRecords = parsedRecords.data.length;
  const fileSize = fileInfo ? fileInfo.size : 0;

  const issueColumns = useMemo(() => [
    {
      title: 'Record ID',
      dataIndex: 'batchRecordId',
      key: 'batchRecordid'
    },
    {
      title: 'Line',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: 'Issue',
      dataIndex: 'warningMessage',
      key: 'warningMessage',
      render: (text: string) => <span dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, '<br>') }} />
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Raw Data',
      dataIndex: 'rawData',
      key: 'rawData'
    }
  ], []);

  const handleShowRevertWizard = (batchId: string) => {
    setRevertBatchId(batchId);
    setShowRevertWizard(true);
  };

  const handleHideRevertWizard = () => {
    setShowRevertWizard(false);
    setRevertStep(0);
    setRevertBatchId(null);
    setRevertResult(null);
    loadBatches(); // Reload batch table
  };

  const handleConfirmRevert = async () => {
    if (!revertBatchId) return;
    setProcessing(true);
    try {
      const response = await bulkUploadService.revertBatch(revertBatchId);
      setRevertResult(response);
      setRevertStep(1);
    } catch (err: any) {
      dispatch(
        asyncLaunchNotification({
          type: 'error',
          config: {
            message: t('admin.bulkUploaderTab.revertFailed'),
            description: err.message || 'Revert failed'
          }
        })
      );
    } finally {
      setProcessing(false);
    }
  };

  // Prepare table columns for batches
  const batchColumns = useMemo(() => [
    {
      title: 'Batch ID',
      dataIndex: 'batchId',
      key: 'batchId'
    },
    {
      title: 'Type',
      dataIndex: 'dataType',
      key: 'dataType'
    },
    {
      title: 'Records',
      dataIndex: 'totalRecords',
      key: 'totalRecords'
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => moment(text).format('DD MMM YYYY HH:mm')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: any) => (
        record.status === 'COMPLETED' ? (
          <Button type="link" onClick={() => handleShowRevertWizard(record.batchId)}>
            {t('admin.bulkUploaderTab.revert')}
          </Button>
        ) : null
      )
    }
  ], [t]);

  // Prepare table data for batches
  const batchTableData = useMemo(() => {
    return batches.map((batch, index) => ({
      key: index,
      ...batch
    }));
  }, [batches]);

  const handleCreateBatch = async () => {
    if (!csvData)
    {
      playAnimationOfError();
      return;
    }

    setProcessing(true);
    try {
      const response = await bulkUploadService.uploadData({
        dataType: form.getFieldValue('dataType'),
        csvData
      });
      setUploadBatchResult(response);
      if (response.totalInvalidRecords > 0) {
        // Fetch records with issues
        const issues = await bulkUploadService.getBatchIssue(response.batchId);
        // Load up record into issue table
        setIssueTableData(issues.map((issue, index) => ({
          key: index,
          ...issue
        })));
      }
      setCurrentStep(1);
    } catch (err: any) {
      dispatch(
        asyncLaunchNotification({
          type: 'error',
          config: {
            message: t('admin.bulkUploaderTab.uploadFailed'),
            description: err.message || 'Upload failed'
          }
        })
      );
    } finally {
      setProcessing(false);
    }
  };

  const handleCommitBatch = async () => {
    setProcessing(true);
    try {
      const response = await bulkUploadService.commitBatch(uploadBatchResult.batchId);
      setCommitBatchResult(response);
      setCurrentStep(2);
    } catch (err: any) {
      dispatch(
        asyncLaunchNotification({
          type: 'error',
          config: {
            message: t('admin.bulkUploaderTab.uploadFailed'),
            description: err.message || 'Upload failed'
          }
        })
      );
    } finally {
      setProcessing(false);
    }
  };

  const resetWizard = () => {
    form.resetFields();
    setCsvData(null);
    setFileInfo(null);
    setUploadBatchResult(null);
    setCommitBatchResult(null);
    setIssueTableData([]);
    setCurrentStep(0);
    setShowFileUploadSection(false);
  };

  const playAnimationOfError = () => {
    setAnimateError(true);
    setTimeout(() => {
      setAnimateError(false);
    }, 500);
  };

  const handleShowUploadCard = () => {
    setShowUploadWizard(true);
  };

  const handleHideUploadCard = async () => {
    if (uploadBatchResult) {
      try {
        await bulkUploadService.cancelBatch(uploadBatchResult.batchId);
      } catch (err: any) {
        dispatch(
          asyncLaunchNotification({
            type: 'error',
            config: {
              message: t('admin.bulkUploaderTab.updateBatchFailed'),
              description: err.message || t('admin.bulkUploaderTab.updateBatchFailed')
            }
          })
        );
      }
    }
    setShowUploadWizard(false);
    resetWizard();
    loadBatches(); // Reload batch table
  };


  const handleCompleteUpload = async () => {
    setShowUploadWizard(false);
    resetWizard();
    loadBatches(); // Reload batch table
  };

  const hasInvalidRecords = useMemo(() => {
    return issueTableData.some(issue => issue.status === 'INVALID');
  }, [issueTableData]);

  const templateLinks = [
    { value: 'users', link: '/csv/bulk-user-template.csv', label: t(`admin.bulkUploaderTab.users`)  },
    { value: 'deliverables', link: '/csv/bulk-deliverable-template.csv', label: t(`admin.bulkUploaderTab.deliverables`) },
    { value: 'roles', link: '/csv/bulk-role-template.csv', label: t(`admin.bulkUploaderTab.roles`) },
    { value: 'companyRoles', link: '/csv/bulk-company-role-template.csv', label: t(`admin.bulkUploaderTab.companyRoles`)  },
    { value: 'phases', link: '/csv/bulk-phase-template.csv', label: t(`admin.bulkUploaderTab.phases`) },
    { value: 'locations', link: '/csv/bulk-location-template.csv', label: t(`admin.bulkUploaderTab.locations`) }
  ];

  const handleDataTypeChange = (value: string) => {
    form.setFieldsValue({ dataType: value });
    setShowFileUploadSection(true);
    const template = templateLinks.find((item) => item.value === value);
    setTemplateLink(template ? template.link : null);
  };

  return (
    <>
      <TabContainer>
        <div
          className="pt-20 overflow-auto"
          style={{ paddingLeft: marginLG, paddingRight: marginLG, width: '100%' }}
        >
          {!showUploadWizard && !showRevertWizard && (
            <Card
              title={t('admin.bulkUploaderTab.batchStatus')}
              extra={<Button type="primary"
                             onClick={handleShowUploadCard}>{t('admin.bulkUploaderTab.uploadCsv')}</Button>}
              style={{ width: '100%' }}
              className="admin-card"
            >
              <Table columns={batchColumns} dataSource={batchTableData} pagination={false} />
            </Card>
          )}

          {showUploadWizard && (
            <Card
              title={t('admin.bulkUploaderTab.uploadCsv')}
              extra={<Button onClick={handleHideUploadCard}>{t('admin.bulkUploaderTab.cancel')}</Button>}
              style={{ width: '100%' }}
              className="admin-card"
            >
              <Steps current={currentStep} style={{ marginBottom: 20 }}>
                <Step title={t('admin.bulkUploaderTab.uploadFile')} />
                <Step title={t('admin.bulkUploaderTab.validation')} />
                <Step title={t('admin.bulkUploaderTab.review')} />
              </Steps>
              <Form
                layout="vertical"
                form={form}
                className={`${animateError && 'error-animation'}`}
              >
                {currentStep === 0 && (
                  <>
                    <Row gutter={32}>
                      <Col span={24}>
                        <Form.Item
                          name="dataType"
                          label={t('admin.bulkUploaderTab.dataType')}
                          rules={[{ required: true, message: t('admin.bulkUploaderTab.enterDataType') }]}
                        >
                          <Select onChange={handleDataTypeChange}>
                            {templateLinks.map((item) => (
                              <Select.Option key={item.value} value={item.value}>
                                {item.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        {showFileUploadSection && (
                          <>
                            <Form.Item label={t('admin.bulkUploaderTab.downloadCsvTemplate')}>
                              {templateLink && (
                                <Link href={templateLink} target="_blank">
                                  {t('admin.bulkUploaderTab.downloadTemplate').replace('{dataType}', form.getFieldValue('dataType'))}
                                </Link>
                              )}
                            </Form.Item>
                            <Form.Item
                              label={t('admin.bulkUploaderTab.uploadCsvFile')}
                              rules={[{ required: true, message: t('admin.bulkUploaderTab.pleaseUploadCsvFile') }]}
                            >
                              <input type="file" accept=".csv" onChange={handleFileUpload} style={{ width: '100%' }} />
                            </Form.Item>
                          </>
                        )}
                      </Col>
                    </Row>
                    <Row gutter={32}>
                      <Col span={24}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', width: '100%' }}>
                          <Button type="primary" onClick={handleCreateBatch}
                                  disabled={!csvData || !form.getFieldValue('dataType')}>
                            {t('admin.bulkUploaderTab.next')}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                {currentStep === 1 && csvData && (
                  <>
                    <Tabs defaultActiveKey="1" style={{ marginBottom: 20 }}>
                      <TabPane tab={t('admin.bulkUploaderTab.validationResult')} key="1">
                        <p><strong>Batch ID:</strong> {uploadBatchResult.batchId}</p>
                        <p><strong>File Size:</strong> {fileSize} bytes</p>
                        <p><strong>Total Records:</strong> {uploadBatchResult.totalRecords}</p>
                        <p><strong>Valid Records:</strong> {uploadBatchResult.totalValidRecords}</p>
                        <p><strong>Invalid Records:</strong> {uploadBatchResult.totalInvalidRecords}</p>
                      </TabPane>
                      {uploadBatchResult.totalInvalidRecords > 0 && (
                        <TabPane tab={t('admin.bulkUploaderTab.issues').replace('{count}', uploadBatchResult.totalInvalidRecords)} key="2">
                          <Table columns={issueColumns} dataSource={issueTableData} pagination={false} />
                        </TabPane>
                      )}
                    </Tabs>
                    <Row gutter={32}>
                      <Col span={24}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', width: '100%' }}>
                          {/* <Button onClick={goToPreviousStep}>
                            {t('admin.bulkUploaderTab.prev')}
                          </Button> */}
                          <Button type="primary" onClick={handleCommitBatch} loading={processing}
                                  disabled={hasInvalidRecords}>
                            {t('admin.bulkUploaderTab.next')}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                {currentStep === 2 && (
                  <>
                    <Row gutter={32}>
                      <Col span={24}>
                        <Card type="inner" title={t('admin.bulkUploaderTab.review')}>
                          {commitBatchResult.totalError === 0 &&
                            <p><strong>Imported record</strong> {commitBatchResult.totalSuccess}</p>
                          }
                          {commitBatchResult.totalError > 0 &&
                            <p><strong>Imported
                              record</strong> {commitBatchResult.totalSuccess} ({commitBatchResult.totalError} error)
                            </p>
                          }
                        </Card>
                      </Col>
                    </Row>
                    <br />
                    <Row gutter={32}>
                      <Col span={24}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', width: '100%' }}>
                          <Button type="primary" onClick={handleCompleteUpload}>
                            {t('admin.bulkUploaderTab.ok')}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </Form>
            </Card>
          )}

          {showRevertWizard && (
            <Card
              title={t('admin.bulkUploaderTab.revertBatch')}
              extra={<Button onClick={handleHideRevertWizard}>{t('admin.bulkUploaderTab.cancel')}</Button>}
              style={{ width: '100%' }}
              className="admin-card"
            >
              <Steps current={revertStep} style={{ marginBottom: 20 }}>
                <Step title="Confirmation" />
                <Step title="Result" />
              </Steps>
              {revertStep === 0 && (
                <>
                  <p>
                    {t('admin.bulkUploaderTab.revertConfirmation')
                      .replace('{recordCount}',
                        (batches.find(batch => batch.batchId === revertBatchId)?.totalRecords || 0).toString()
                      )}
                  </p>
                  <Row gutter={32}>
                    <Col span={24}>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', width: '100%' }}>
                        <Button onClick={handleHideRevertWizard}>
                          No
                        </Button>
                        <Button type="primary" onClick={handleConfirmRevert} loading={processing}>
                          Yes
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              {revertStep === 1 && (
                <>
                  <p>
                    {t('admin.bulkUploaderTab.revertResult')
                      .replace('{successCount}', (revertResult?.totalSuccess || 0).toString())
                      .replace('{errorCount}', (revertResult?.totalError || 0).toString())}
                  </p>
                  <Row gutter={32}>
                    <Col span={24}>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', width: '100%' }}>
                        <Button type="primary" onClick={handleHideRevertWizard}>
                          {t('admin.bulkUploaderTab.ok')}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </Card>
          )}
        </div>
      </TabContainer>
    </>
  );
};
