import { http } from '@infrastructure/http/axios.instance';
import { IBulkData, IBulkUploadBatch, IUploadDataResponse, IBulkUploadRecord, ICommitBatchResponse } from '@models/bulk-upload.model';
import { StringORNumber } from './utils.repository';
import { IQueryParams } from '../../models/pagination';

const path = 'bulk-upload-batches';

export const bulkUploadRepository = {
  getAll: async (queryParams: IQueryParams) => {
    const { sort, page, size } = queryParams;
    const requestUrl = `${path}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;

    const batches = await http.get<IBulkUploadBatch[]>(requestUrl);
    return batches;
  },
  getById: async (id: StringORNumber) => {
    const requestUrl = `${path}/${id}`;
    const promiseGetByID = await http.get<IBulkUploadBatch>(requestUrl);
    return promiseGetByID;
  },

  uploadData: async (bulkData: IBulkData): Promise<IUploadDataResponse> => {
    const requestUrl = `${path}/upload`;
    return handleRequest(() => http.post<IUploadDataResponse>(requestUrl, bulkData));
  },

  getBatchIssue: async (id: StringORNumber): Promise<IBulkUploadRecord[]> => {
    const requestUrl = `${path}/issues/${id}`;
    return handleRequest(() => http.post<IBulkUploadRecord[]>(requestUrl, {}));
  },

  commitBatch: async (id: StringORNumber): Promise<ICommitBatchResponse> => {
    const requestUrl = `${path}/commit/${id}`;
    return handleRequest(() => http.post<ICommitBatchResponse>(requestUrl, {}));
  },

  revertBatch: async (id: StringORNumber): Promise<ICommitBatchResponse> => {
    const requestUrl = `${path}/revert/${id}`;
    return handleRequest(() => http.post<ICommitBatchResponse>(requestUrl, {}));
  },

  cancelBatch: async (id: StringORNumber): Promise<ICommitBatchResponse> => {
    const requestUrl = `${path}/cancel/${id}`;
    return handleRequest(() => http.post<ICommitBatchResponse>(requestUrl, {}));
  },
};

/**
 * Generic function to handle API requests and extract HTTP 400 error messages.
 */
const handleRequest = async <T>(request: () => Promise<{ data: T }>): Promise<T> => {
  try {
    const response = await request();
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 400) {
      throw new Error(error.response.data?.title || 'Bad Request');
    }
    throw new Error(error.message || 'An unexpected error occurred');
  }
};
