import React, { forwardRef, useImperativeHandle } from 'react';
import { Table, theme, Progress } from 'antd';

import type { ColumnsType, TableProps } from 'antd/es/table';
import { IProject } from '@models/project-model';
import { IProjectStatus } from '@models/project-status.model';
import { IProjectType } from '@models/project-type.model';
import { ISite } from '@models/site.model';
import { EmptyCellTable } from '@components/Utils/EmptyCellTable';
import { ChevronRightSvgIcon } from '@components/Icons/ChevronRightSvgIcon';
import { SorterResult, SortOrder } from 'antd/es/table/interface';
import { useEffect, useState } from 'react';
import ProjectDisplayOptions from './ProjectDisplayOptions';

interface OverviewTableProps {
  projectList: IProject[];
  loading: boolean;
  statusFilters: { text: string; value: string }[];
  heightContainer: number;
  setSortingKey: (key: string) => void;
  setSortingOrder: (order: string) => void;
  setPageNumber: (pageNumber: number) => void;
  setItemsPerPage: (itemsPerPage: number) => void;
  sortingOrder: string;
  navigateToProjectDetails: (record: IProject) => void;
}

const initialColumnsState: { [key: string]: boolean } = {
  id: true,
  projectStatus: true,
  projectType: true,
  location: true,
  market: true,
  progress: true,
  managerName: true,
  openDate: true,
  button: true,
};

export const OverviewTable = forwardRef(
  (
    {
      projectList,
      loading,
      statusFilters,
      heightContainer,
      setSortingKey,
      setSortingOrder,
      setPageNumber,
      setItemsPerPage,
      sortingOrder,
      navigateToProjectDetails,
    }: OverviewTableProps,
    ref
  ) => {
    const [openDisplayOptions, setOpenDisplayOptions] = useState(false);
    const [columnsState, setColumnsState] = useState(initialColumnsState);
    const [enrichedProjectList, setEnrichedProjectList] = useState<IProject[]>([]);

    const onClose = () => {
      setOpenDisplayOptions(false);
    };

    const {
      token: { colorBorderSecondary, colorPrimaryText },
    } = theme.useToken();

    const buildProjectStatus = (status: IProjectStatus) => {
      if (status && status.id && status.name) return <>{status.name}</>;
      return <EmptyCellTable />;
    };

    const buildProjectType = (type: IProjectType) => {
      if (type && type.id && type.name) return <>{type.name}</>;
      return <EmptyCellTable />;
    };

    const ViewMoreCell = () => {
      return (
        <div className="flex flex-row items-center select-none">
          <span className="ml-10" style={{ color: colorPrimaryText }}>
            View
          </span>
          <ChevronRightSvgIcon className="ml-10" style={{ color: colorPrimaryText }} />
        </div>
      );
    };

    const columns: ColumnsType<IProject> = [
      {
        title: 'Project ID',
        dataIndex: 'id',
        key: 'id',
        width: '10%',
        render: value => {
          return value || <EmptyCellTable />;
        },
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: true,
        fixed: 'left',
      },
      {
        title: 'Location',
        dataIndex: 'site.location.name',
        key: 'location',
        width: '15%',
        render: (site: ISite, record: any) => record.site?.location?.name || <EmptyCellTable />,
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: true,
        fixed: 'left',
      },
      {
        title: 'Status',
        dataIndex: 'projectStatus',
        key: 'projectStatus',
        render: (status: IProjectStatus) => buildProjectStatus(status),
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: true,
        filters: statusFilters,
        onFilter: (value, record) => record.projectStatus?.name === value,
      },
      {
        title: 'Type',
        dataIndex: 'projectType',
        key: 'projectType',
        render: (type: IProjectType) => buildProjectType(type),
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: true,
      },
      {
        title: 'Market',
        dataIndex: 'site.location.market',
        key: 'market',
        render: (site: ISite, record: any) => record.site?.location?.market?.description || <EmptyCellTable />,
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: true,
      },
      {
        title: 'Progress',
        dataIndex: 'progress',
        key: 'progress',
        render: (progress: number) => (
          <Progress percent={progress} size="small" status={progress === 100 ? 'success' : 'active'} format={percent => `${percent}%`} />
        ),
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: true,
      },
      {
        title: 'Manager',
        dataIndex: 'managerName',
        key: 'managerName',
        width: '15%',
        render: (managerName: string) => managerName || <EmptyCellTable />,
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: true,
      },
      {
        title: 'Open Date',
        dataIndex: 'site.openDate',
        key: 'openDate',
        width: '15%',
        render: (site: ISite, record: any) => record.site?.openDate || <EmptyCellTable />,
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: true,
      },
      {
        title: '',
        dataIndex: 'button',
        key: 'button',
        width: '10%',
        render: (text, record: IProject) => (
          <div onClick={() => navigateToProjectDetails(record)} className="cursor-pointer">
            <ViewMoreCell />
          </div>
        ),
      },
    ];

    const [visibleColumns, setVisibleColumns] = useState(columns.filter(column => column?.key && columnsState[String(column.key)]));

    useEffect(() => {
      prepareDataToRender();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnsState]);

    useEffect(() => {
      if (statusFilters.length > 0) {
        const updatedColumns = columns.map(col => {
          if (col.key === 'projectStatus') {
            return { ...col, filters: statusFilters };
          }
          return col;
        });

        setVisibleColumns(updatedColumns.filter(column => column?.key && columnsState[String(column.key)]));
      }
      /* eslint-disable react-hooks/exhaustive-deps */
    }, [statusFilters, columnsState]);

    useEffect(() => {
      if (!projectList || projectList.length === 0) {
        setEnrichedProjectList([]);
        return;
      }

      const enrichedProjects = projectList.map(project1 => {
        const project: any = project1;
        if (!project.projectDeliverableList || project.projectDeliverableList.length === 0) {
          return { ...project, progress: 0 };
        }

        const processedDeliverables = project.projectDeliverableList.map(deliverable => {
          // Extract required properties
          const completedFlag = deliverable.completedFlag || false;
          const atRiskFlag = deliverable.atRiskFlag || false;
          const plannedFinishDate = deliverable.plannedFinishDate ? new Date(deliverable.plannedFinishDate) : null;
          const plannedStartDate = deliverable.plannedStartDate ? new Date(deliverable.plannedStartDate) : null;

          // Calculate status
          let status = 'On Track';

          if (completedFlag) {
            status = 'Completed';
          } else if (atRiskFlag) {
            status = 'At Risk';
          } else if (plannedFinishDate && plannedFinishDate < new Date() && !completedFlag) {
            status = 'Late';
          } else if (plannedStartDate && plannedStartDate > new Date() && !completedFlag) {
            status = 'Not Started';
          }

          return {
            status,
          };
        });

        // Calculate % completed deliverable compares to all deliverables in project
        const totalDeliverables = processedDeliverables.length;
        const completedDeliverables = processedDeliverables.filter(d => d.status === 'Completed').length;
        const progressPercentage = totalDeliverables > 0 ? Math.round((completedDeliverables / totalDeliverables) * 100) : 0;

        return {
          ...project,
          progress: progressPercentage,
        };
      });

      setEnrichedProjectList(enrichedProjects);
    }, [projectList]);

    const prepareDataToRender = () => {
      const filteredVisibleData = columns.filter(column => column?.key && columnsState[String(column.key)]);
      setVisibleColumns(filteredVisibleData);
    };

    const handleTableChange: TableProps<IProject>['onChange'] = (pagination, filter, sorter) => {
      const _sorter = sorter as SorterResult<IProject>;
      setSortingKey(String(_sorter?.field));
      setSortingOrder(_sorter?.order === 'descend' ? 'desc' : _sorter?.order === 'ascend' ? 'asc' : sortingOrder);
      setPageNumber(pagination.current !== undefined ? pagination.current - 1 : 0);
    };

    // Expose method to get CSV data
    useImperativeHandle(ref, () => ({
      showDisplayOptions: () => {
        setOpenDisplayOptions(true);
      },
      getCSVData: () => {
        // Create headers array based on visible columns
        const headers: string[] = [];
        if (columnsState.id) headers.push('Project ID');
        if (columnsState.projectStatus) headers.push('Status');
        if (columnsState.projectType) headers.push('Type');
        if (columnsState.location) headers.push('Location');
        if (columnsState.market) headers.push('Market');
        if (columnsState.progress) headers.push('Progress');
        if (columnsState.managerName) headers.push('Manager');
        if (columnsState.openDate) headers.push('Open Date');

        // Format the data based on visible columns
        const rows = enrichedProjectList.map(p => {
          const project: any = p;
          const row: (string | number)[] = [];
          if (columnsState.id) row.push(project.id || '');
          if (columnsState.projectStatus) row.push(project.projectStatus?.name || '');
          if (columnsState.projectType) row.push(project.projectType?.name || '');
          if (columnsState.location) row.push(project.site?.location?.name || '');
          if (columnsState.market) row.push(project.site?.location?.market?.description || '');
          if (columnsState.progress) row.push(project.progress || 0);
          if (columnsState.managerName) row.push(project.managerName || '');
          if (columnsState.openDate) row.push(project.site?.openDate || '');
          return row;
        });

        // Return data with headers as first row
        return [headers, ...rows];
      },
    }));

    return (
      <>
        <Table
          style={{ border: `1px solid ${colorBorderSecondary}` }}
          size="small"
          rowKey="id"
          columns={visibleColumns}
          dataSource={enrichedProjectList}
          onChange={handleTableChange}
          pagination={false}
          loading={loading}
          scroll={{ y: heightContainer - 150 }}
        />
        <ProjectDisplayOptions
          columnsState={columnsState}
          setItemsPerPage={setItemsPerPage}
          setColumnsState={setColumnsState}
          openDisplayOptions={openDisplayOptions}
          onClose={onClose}
        />
      </>
    );
  }
);

export default OverviewTable;
