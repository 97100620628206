import { SVGProps } from "react";

type IProps = SVGProps<SVGSVGElement>

export const WarningSvgIcon = (props: IProps) => {

    const { ...restProps } = props;

    return (
        <svg width="20" height="20" viewBox="0 0 24 24" stroke="currentColor" fill="#ffffff" xmlns="http://www.w3.org/2000/svg" {...restProps}>
          <path d="M12.5 10V14M12.5 17V15.5M14.2483 5.64697L20.8493 17.5287C21.5899 18.8618 20.6259 20.5 19.101 20.5H5.89903C4.37406 20.5 3.41013 18.8618 4.15072 17.5287L10.7517 5.64697C11.5137 4.27535 13.4863 4.27535 14.2483 5.64697Z"  stroke-width="1.2"/>
        </svg>

    )
}
