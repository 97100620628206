import { isFulfilled } from '@reduxjs/toolkit';
import { IBulkUploadBatch, defaultValue } from "../../models/bulk-upload.model";
import { createEntitySlice, EntityState, serializeAxiosError } from "../reducer.util";
import { createAsyncThunk } from "@reduxjs/toolkit"
import { bulkUploadService } from "../../services/bulk-upload.service";
import { IQueryParams } from "../../models/pagination";

export const getEntities = createAsyncThunk(
  'bulkUploadBatch/fetch_entity_list', 
  async (queryParams: IQueryParams) => {
      return bulkUploadService.getAll(queryParams);
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  'bulkUploadBatch/fetch_entity',
  async (id: string | number) => {
    return bulkUploadService.getById(id);
  },
  { serializeError: serializeAxiosError }
);

const initialState: EntityState<IBulkUploadBatch> = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

export const BulkUploadBatchSlice = createEntitySlice({
    name: 'bulkUploadBatch',
    initialState,
    reducers: {
      clearEntity: (state,) => {
        state.entity = defaultValue;
      },
    },
    extraReducers(builder) {
        builder
          .addCase(getEntity.fulfilled, (state, action) => {
            state.loading = false;
            state.entity = action.payload.data;
          })
          .addMatcher(isFulfilled(getEntities), (state, action) => {
            const { data, headers } = action.payload;
            const xTotalCount = headers['x-total-count'];
            return {
              ...state,
              loading: false,
              entities: data,
              totalItems: xTotalCount ? parseInt(xTotalCount, 10) : data.length,
            };
          })
      }
  });
  
  export const { reset, clearEntity } = BulkUploadBatchSlice.actions;
  
  // Reducer
  export default BulkUploadBatchSlice.reducer;