import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import setupAxiosInterceptors from './infrastructure/http/axios.instance';
import reportWebVitals from './reportWebVitals';

import { logout } from './services/auth.service';

const container = document.getElementById('root')!;
const root = createRoot(container);

setupAxiosInterceptors(logout);

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_SERVER) {
  const { worker } = require('./__testing__/mocks/browser')
  worker.start({
    onUnhandledRequest: "bypass",
  })
}

root.render(
  <App />
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
