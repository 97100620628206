import { bulkUploadRepository } from '@infrastructure/repositories/bulk-upload.repository';

export const bulkUploadService = {
  uploadData: bulkUploadRepository.uploadData,
  getBatchIssue: bulkUploadRepository.getBatchIssue,
  commitBatch: bulkUploadRepository.commitBatch,
  revertBatch: bulkUploadRepository.revertBatch,
  cancelBatch: bulkUploadRepository.cancelBatch,
  getAll: bulkUploadRepository.getAll,
  getById: bulkUploadRepository.getById,
}
