import { IRoleBinding } from '@models/role-binding.model';
import { IProjectDeliverable, ProjectDeliverableTimeLineResponse } from 'models/project-deliverable';
import { IQueryParamsGeneric } from 'models/utils';
import { ProjectDeliverableMapper } from '../../mappers/ProjectDeliverable.mapper';
import { ProjectDeliverableTimelineMapper } from '../../mappers/ProjectDeliverableTimeLine.mapper';
import {
  IProject,
  IProjectOverview,
  IProjectStatusOverview,
  IProjectTypeOverview,
  IRequestProjectDeliverableDetailsById,
  ITeamMemberItemCustom,
  ITeamMembersByGroup,
} from '../../models/project-model';
import { IEvaluationParams } from '../../store/slices/project-deliverable-timeline';
import { http } from '../http/axios.instance';
import {
  IProjectCreationForm,
  IProjectTemplate,
  IProjectTemplateExtended,
  IProjectTemplateSave,
  IRole,
} from './../../models/project-template-model';
import { ICreateTeamMember, IProjectRoleAndUserAssigned } from './../../store/slices/project-teams-extended';
import { StringORNumber } from './utils.repository';
import { ProjectDeliverableGanttMapper } from '@mappers/ProjectDeliverableGantt.mapper';
import { ITemplateDeliverable } from '@models/template-deliverable.model';
import { ITemplateDeliverableApproval } from '@models/template-deliverable-approval';
import { IQueryParams } from '@models/pagination';

const path = 'projects';
const pathProjectsExtended = 'projects-extended';
const pathProjectExtended = 'project-extended';
const templatesPath = 'project-templates';
const templateDeliverablesPath = 'template-deliverables';
const sitePath = 'site';

export const projectsRepository = {
  getAllEx: async (queryParams: IQueryParamsGeneric) => {
    const { nameFilter = '' } = queryParams;
    delete queryParams.nameFilter;
    const params = new URLSearchParams({
      ...queryParams,
      cacheBuster: `${new Date().getTime()}`,
    });

    if (nameFilter) {
      params.append('id.equals', nameFilter.toString());
    }

    const requestUrl = `${path}/deliverables?${params.toString()}`;

    const promiseGetAll = await http.get<IProject[]>(requestUrl);
    return promiseGetAll;
  },
  getAll: async (queryParams: IQueryParamsGeneric) => {
    const params = new URLSearchParams({
      ...queryParams,
      cacheBuster: `${new Date().getTime()}`,
    });
    const requestUrl = `${path}?${params.toString()}`;

    const promiseGetAll = await http.get<IProject[]>(requestUrl);
    return promiseGetAll;
  },
  getBySiteId: async (queryParams: IQueryParamsGeneric) => {
    const params = new URLSearchParams({
      ...queryParams,
      cacheBuster: `${new Date().getTime()}`,
    });
    const requestUrl = `${path}?${params.toString()}`;

    const promiseGetAll = await http.get<IProject[]>(requestUrl);
    return promiseGetAll;
  },
  getById: async (id: StringORNumber) => {
    const requestUrl = `${path}/${id}`;
    const promiseGetByID = await http.get<IProject>(requestUrl);
    return promiseGetByID;
  },
  getProjectOverview: async (id: StringORNumber) => {
    const requestUrl = `${pathProjectExtended}/${id}/overview`;
    const promiseGetByID = await http.get<IProjectOverview>(requestUrl);
    return promiseGetByID;
  },
  getProjectOverviewTeam: async (request: { id: StringORNumber; queryParams: IQueryParams }) => {
    const {
      id,
      queryParams: { sort, page, size },
    } = request;
    const requestUrl = `${pathProjectsExtended}/${id}/team-members${
      size ? `?page=${page}&size=${size}&sort=${sort}&` : '?'
    }cacheBuster=${new Date().getTime()}`;
    const promiseGetByID = await http.get<ITeamMemberItemCustom[]>(requestUrl);
    return promiseGetByID;
  },
  createProjectDeliverable: async (data: IProjectDeliverable) => {
    const requestUrl = `/project-deliverables`;
    return await http.post(requestUrl, data);
  },
  getProjectDeliverables: async (id: StringORNumber) => {
    const requestUrl = `/project-deliverables/by-project/${id}`;
    const promiseGetByID = await http.get<IProjectDeliverable[]>(requestUrl);

    const result = ProjectDeliverableMapper.toDomain(promiseGetByID.data);
    return result;
  },
  getProjectDeliverableDetailById: async (request: IRequestProjectDeliverableDetailsById) => {
    const requestUrl = `/project-deliverables/${request.projectDeliverableId}`;
    const promiseGetByID = await http.get<IProjectDeliverable>(requestUrl);

    const result = ProjectDeliverableMapper.toDomain([promiseGetByID.data]);
    return result;
  },
  getProjectDeliverablesDataGantt: async (id: StringORNumber) => {
    const requestUrl = `/project-deliverables/by-project/${id}`;

    const promiseGetByID = await http.get<IProjectDeliverable[]>(requestUrl);

    const result = ProjectDeliverableGanttMapper.toDomain(promiseGetByID.data);
    return result;
  },
  evaluateTimelineDeliverable: async (requestParams: IEvaluationParams) => {
    const { projectId, projectDeliverableId, requestBody } = requestParams;
    const requestUrl = `${pathProjectExtended}/${projectId}/validate-planning/${projectDeliverableId}`;
    const promise = await http.post<ProjectDeliverableTimeLineResponse>(requestUrl, requestBody);
    const result = ProjectDeliverableTimelineMapper.toDomain([promise.data]);
    return result;
  },
  applyChangesTimelineDeliverable: async (requestParams: IEvaluationParams) => {
    const { projectId, projectDeliverableId, requestBody } = requestParams;
    const requestUrl = `${pathProjectExtended}/${projectId}/apply-planning/${projectDeliverableId}`;
    const promise = await http.post<ProjectDeliverableTimeLineResponse>(requestUrl, requestBody);
    return promise;
  },
  create: async (entity: IProject): Promise<IProject> => {
    const requestUrl = `${path}`;
    const promiseCreate = await http.post<IProject>(requestUrl, entity);
    return promiseCreate.data;
  },
  update: async (id: string | number, entity: IProject): Promise<IProject> => {
    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.put<IProject>(requestUrl, entity);
    return promiseUpdate.data;
  },
  linkOxBlueIdToProject: async (id: string | number, entity: IProject): Promise<IProject> => {
    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.patch<IProject>(requestUrl, entity);
    return promiseUpdate.data;
  },
  unlinkOxBlueIdToProject: async (id: string | number): Promise<IProject> => {
    const requestUrl = `${pathProjectsExtended}/${id}/update-oxblue-projectid`;
    const promiseUpdate = await http.patch<IProject>(requestUrl, { id: id, oxblueProjectId: null });
    return promiseUpdate.data;
  },
  updateNickNameForProject: async (id: string | number,entity: IProject): Promise<IProject> => {
    const requestUrl = `${pathProjectsExtended}/${id}/update-nickname`;
    const promiseUpdate = await http.patch<IProject>(requestUrl, { id: id, nickname: entity.nickname });
    return promiseUpdate.data;
  },
  updateArchiveStatus: async (id: string | number, entity: IProject): Promise<IProject> => {
    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.patch<IProject>(requestUrl, entity);
    return promiseUpdate.data;
  },
  updateProjectEntity: async (id: string | number, entity: IProject): Promise<IProject> => {
    const requestUrl = `${path}/${id}`;
    const promiseUpdate = await http.patch<IProject>(requestUrl, entity);
    return promiseUpdate.data;
  },
  updateStatus: async (id: string | number, entity: IProject): Promise<IProjectStatusOverview> => {
    const requestUrl = `${pathProjectsExtended}/${id}/update-status`;
    const promiseUpdate = await http.patch<IProjectStatusOverview>(requestUrl, entity);
    return promiseUpdate.data;
  },
  updateType: async (payload: IProjectTypeOverview): Promise<IProjectStatusOverview> => {
    const requestUrl = `${pathProjectsExtended}/${payload.id}/update-type`;
    const promiseUpdate = await http.patch(requestUrl, payload);
    return promiseUpdate.data;
  },
  delete: async (id: string | number) => {
    const requestUrl = `${path}/${id}`;
    const promiseDelete = await http.delete<IProject>(requestUrl);
    return promiseDelete;
  },
  getTemplates: async (search: String) => {
    const params = new URLSearchParams({
      cacheBuster: `${new Date().getTime()}`,
    });
    const searchFilterParam = search ? '&search=' + search : '';
    const requestUrl = `${templatesPath}/filter-by?${params.toString()}${searchFilterParam}`;

    const promise = await http.get<IProjectTemplateExtended[]>(requestUrl);
    return promise;
  },
  getTemplateById: async (id: StringORNumber) => {
    const requestUrl = `${templatesPath}/${id}`;
    const promiseGetByID = await http.get<IProjectTemplateExtended>(requestUrl);
    return promiseGetByID;
  },
  getTemplatesRoles: async (templateId: string | number) => {
    const requestUrl = `${templatesPath}`;
    const promise = await http.get<IRole[]>(`${requestUrl}/${templateId}/get-required-roles`);
    return promise;
  },
  getTemplateDeliverables: async (templateId: string | number) => {
    const params = new URLSearchParams({
      cacheBuster: `${new Date().getTime()}`,
    });
    const templateIdFilterParam = templateId ? '&templateId.equals=' + templateId : '';
    const requestUrl = `${templateDeliverablesPath}?${params.toString()}${templateIdFilterParam}`;
    const promise = await http.get<ITemplateDeliverable[]>(`${requestUrl}`);
    return promise;
  },
  getTemplateDeliverableApprovals: async (templateId: string | number) => {
    const params = new URLSearchParams({
      cacheBuster: `${new Date().getTime()}`,
    });
    const templateIdFilterParam = '&templateId=' + templateId;
    const requestUrl = `/template-deliverable-approvals/by-template?${params.toString()}${templateIdFilterParam}`;
    const promise = await http.get<ITemplateDeliverableApproval[]>(`${requestUrl}`);
    return promise;
  },
  applyTemplateToCreateAProject: async (siteId: string | number, entity: IProjectCreationForm) => {
    const requestUrl = `${sitePath}`;
    const promise = await http.post<IProject>(`${requestUrl}/${siteId}/apply-template`, entity);
    return promise;
  },

  getTeamMembers: async (projectId: StringORNumber) => {
    const requestUrl = `${pathProjectsExtended}/${projectId}/team-members/by-group`;
    const promiseGetByID = await http.get<ITeamMembersByGroup>(requestUrl);
    return promiseGetByID;
  },
  updateTeamMember: async (payload: IProjectRoleAndUserAssigned, projectId: string, roleBindingId: string) => {
    const promise = await http.put(`${pathProjectsExtended}/${projectId}/team-members/${roleBindingId}`, payload);
    return promise;
  },

  createTeamMember: async (payload: ICreateTeamMember, projectId: string) => {
    const promise = await http.post(`${pathProjectsExtended}/${projectId}/team-members`, payload);
    return promise;
  },

  deleteTeamMember: async (projectId: string, roleBindingId: string) => {
    const promise = await http.delete(`${pathProjectsExtended}/${projectId}/team-members/${roleBindingId}`);
    return promise;
  },

  getRfiDefaultUsers: async (projectId: StringORNumber) => {
    const requestUrl = `${pathProjectsExtended}/${projectId}/rfi-default-users?cacheBuster=${new Date().getTime()}`;
    const promiseGetAll = await http.get<IRoleBinding[]>(requestUrl);
    return promiseGetAll;
  },
  getChangeOrdersDefaultUsers: async (projectId: StringORNumber) => {
    const requestUrl = `${pathProjectsExtended}/${projectId}/change-orders-default-users?cacheBuster=${new Date().getTime()}`;
    const promiseGetAll = await http.get<IRoleBinding[]>(requestUrl);
    return promiseGetAll;
  },
  duplicateTemplate: async (templateId: string | number) => {
    const promise = await http.post<IProjectTemplateExtended>(`${pathProjectExtended}/${templateId}/duplicate`);
    return promise;
  },
  saveTemplateComplete: async (template: IProjectTemplateSave) => {
    const promise = await http.put<IProjectTemplate>(`${pathProjectExtended}/${template?.id}/complete`, template);
    return promise;
  },
  createTemplate: async (template: IProjectTemplate) => {
    const requestUrl = `${templatesPath}`;
    const promise = await http.post<IProjectTemplate>(`${requestUrl}`, template);
    return promise;
  },
  getReasonCodes: async () => {
    const requestUrl = `/project-deliverable-reasons`;
    const promiseGetAll = await http.get(requestUrl);
    return promiseGetAll;
  },
};
